import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

/******* Calling Dynamic URL ********/
const apiUrl = process.env.REACT_APP_API_KEY;

export const updateVesselLocation = createAsyncThunk(
	'listing/updateVesseLocation',
	async ({ berth_id, token }, thunkAPI) => {
		try {
			const response = await fetch(
				apiUrl + 'get-vessel-location/' + berth_id,
				{
					method: 'GET',
					headers: {
						Accept: 'application/json',
						Authorization: `Bearer ` + token,
						'Content-Type': 'application/json',
					},
				}
			);
			let data = await response.json();
			if (data.status === true) {
				return { ...data };
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e) {
			return thunkAPI.rejectWithValue(e.response.data);
		}
	}
);



const initialState = { // "Initial state for set static lat lng"
	location: { lat: 45.1314415, lng: -66.353824, address: null },
	isNewListingCreated: false,  // Track if a new request was created
}

const listingSlice = createSlice({ // Listing slice for set all listings
	name: 'listing',
	initialState,
	reducers: {
		setSelectedLocation: (state, action) => { // reducer for update lat lng
			var lat_lng = action.payload.lat_lng.split("_")
			state.location = { lat: lat_lng[0], lng: lat_lng[1], address: action.payload.address }
		},
		reset: (state, action) => { // reducer for update lat lng
			state = initialState;
			return state;
		},
		setNewListingCreated: (state, action) => {
			state.isNewListingCreated = action.payload;
		  },
	},
	extraReducers: { // extra reducers for update the data
		[updateVesselLocation.pending]: (state) => {
			state.isFetching = true;
		},
		[updateVesselLocation.fulfilled]: (state, { payload }) => {
			state.location = payload.data;
			state.isUpdate = true;
			return state;
			//state.username = payload.name;
		},
		[updateVesselLocation.rejected]: (state) => {
			state.isFetching = false;
			state.isError = true;
			state.isUpdate = false;
			return state;
		},
	},
});

export const listingActions = listingSlice.actions;

export const listingSelector = (state) => state.listing;

export default listingSlice.reducer
