import React, { useState } from "react";
import Select from "react-select";
// import CreatableSelect from 'react-select/creatable';
import { Controller } from "react-hook-form";
import SearchSelect from "../../selectSearch/SearchSelect";
import SearchSelectCreate from "../../selectSearch/SearchSelectCreate";

const LicenseListing = ({
  handleChangeOtherNormal,
  handleFormDataOther,
  valuesOther,
  values,
  selectValues,
  register,
  error,
  clearErrors,
  setValue,
  getValues,
  control,
}) => {
  return (
    <>
      <p className="good_day_para">
        Please fill the following details for License
      </p>
      <form>
        <div className="row">
          <div className="col-md-12">
            <span className="popup_input_label">
              License Number<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <input
              type="text"
              name="LicenseNumber"
              min="0"
              defaultValue={valuesOther.LicenseNumber}
              className="popup_input"
              id="LicenseNumber"
              {...register("LicenseNumber", {
                required: "This field is required.",
              })}
              onChange={(e) => {
                clearErrors("LicenseNumber");
                handleFormDataOther(e);
              }}
            />
            {error.LicenseNumber && (
              <span className="err-msg">{error?.LicenseNumber?.message}</span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Region<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="LicenseRegion"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelectCreate
                  defaultValue={
                    selectValues.brokerRegion.find(
                      (e) => e.id === getValues("LicenseRegion")
                    )
                      ? {
                        value: selectValues.brokerRegion.find(
                          (e) => e.id === getValues("LicenseRegion")
                        ).id,
                        label: selectValues.brokerRegion.find(
                          (e) => e.id === getValues("LicenseRegion")
                        )?.name,
                      }
                      : {
                        value: getValues("LicenseRegion"),
                        label: getValues("LicenseRegion"),
                      }
                  }
                  onChange={(data, action) => {
                    setValue("LicenseRegion", data.value);
                    handleFormDataOther({
                      target: {
                        value: data.value,
                        name: "LicenseRegion",
                      },
                    });

                    clearErrors("LicenseRegion");
                  }}
                  options={selectValues.brokerRegion.map((berth, index) => {
                    return { value: berth.id, label: berth.name };
                  })}
                />
              )}
            />
            {error.LicenseRegion && (
              <span className="err-msg">{error?.LicenseRegion?.message}</span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Province<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="LicenseProvince"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelectCreate
                  defaultValue={
                    selectValues.states.find(
                      (e) => e.id === getValues("LicenseProvince")
                    )
                      ? {
                        value: selectValues.states.find(
                          (e) => e.id === getValues("LicenseProvince")
                        ).id,
                        label: selectValues.states.find(
                          (e) => e.id === getValues("LicenseProvince")
                        )?.name,
                      }
                      : {
                        value: getValues("LicenseProvince"),
                        label: getValues("LicenseProvince"),
                      }
                  }
                  onChange={(data, action) => {
                    setValue("LicenseProvince", data.value);
                    handleFormDataOther({
                      target: {
                        value: data.value,
                        name: "LicenseProvince",
                      },
                    });

                    clearErrors("LicenseProvince");
                  }}
                  options={selectValues.states.map((berth, index) => {
                    return { value: berth.id, label: berth.name };
                  })}
                />
              )}
            />
            {error.LicenseProvince && (
              <span className="err-msg">{error?.LicenseProvince?.message}</span>
            )}
          </div>

          <div className="col-md-12">
            <span className="popup_input_label">
              License Type<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="LicenseType"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelectCreate
                  defaultValue={
                    selectValues.brokerLicenseTypeGeneral.find(
                      (e) => e.id === getValues("LicenseType")
                    )
                      ? {
                        value: selectValues.brokerLicenseTypeGeneral.find(
                          (e) => e.id === getValues("LicenseType")
                        ).id,
                        label: selectValues.brokerLicenseTypeGeneral.find(
                          (e) => e.id === getValues("LicenseType")
                        )?.name,
                      }
                      : {
                        value: getValues("LicenseType"),
                        label: getValues("LicenseType"),
                      }
                  }
                  onChange={(data, action) => {
                    setValue("LicenseType", data.value);
                    handleFormDataOther({
                      target: {
                        value: data.value,
                        name: "LicenseType",
                      },
                    });

                    clearErrors("LicenseType");
                  }}
                  // onChange={(selectedOption) => {
                  //   setValue("LicenseType", selectedOption?.value);
                  //   handleFormDataOther({
                  //     target: {
                  //       value: selectedOption.value,
                  //       name: "LicenseType",
                  //     },
                  //   });
                  //   clearErrors("LicenseType");
                  // }}
                  options={selectValues.brokerLicenseTypeGeneral.map(
                    (berth, index) => {
                      return { value: berth.id, label: berth.name };
                    }
                  )}
                />
              )}
            />
            {error.LicenseType && (
              <span className="err-msg">{error?.LicenseType?.message}</span>
            )}
          </div>
          {/* <div className="col-md-12">
            <span className="popup_input_label">License Or Quota</span>
          </div>
          <div className="col-md-12">
            <select
              className="popup_input"
              id="license_or_quota"
              name="LicenseOrQuota"
              onChange={handleChangeOtherNormal}
            >
              <option value="9" selected>
                License
              </option>
              <option value="8" selected>
                Quota
              </option>
            </select>
          </div> */}
          {
            values?.ItemType !== 'Package' ?
              <>
                <div className="col-md-12">
                  <span className="popup_input_label">
                    Price<span className="required-field"></span>
                  </span>
                </div>
                <div className="col-md-12">
                  <input
                    type="number"
                    min="1000"
                    name="ItemPrice"
                    defaultValue={valuesOther?.ItemPrice}
                    className="popup_input"
                    {...register("ItemPrice", {
                      required: "This field is required.",
                      validate: {
                        maxNumber: (value) => {
                          if (value < 100) {
                            return "Minimum price should not be less than $100";
                          }
                        },
                      },
                      pattern: {
                        value: /^([1-9][0-9]*)$/,
                        message:
                          "Minimum Price should be numeric and greater than zero.",
                      },
                    })}
                    onChange={(e) => {
                      clearErrors("ItemPrice");
                      handleFormDataOther(e);
                    }}
                  />
                  <br></br>
                  {error.ItemPrice && (
                    <span className="err-msg">{error?.ItemPrice?.message}</span>
                  )}
                </div>
              </> : ''}
        </div>
      </form>
    </>
  );
};

const QuotaListing = ({
  handleChangeOtherNormal,
  handleFormDataOther,
  valuesOther,
  values,
  selectValues,
  register,
  error,
  clearErrors,
  setValue,
  getValues,
  control,
}) => {
  console.log('values', values)
  return (
    <>
      <p className="good_day_para">
        Please fill the following details for Quota
      </p>
      <form>
        <div className="row">
          <div className="col-md-12">
            <span className="popup_input_label">
              License Number<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <input
              type="text"
              name="LicenseNumber"
              min="0"
              defaultValue={valuesOther.LicenseNumber}
              className="popup_input"
              id="LicenseNumber"
              {...register("LicenseNumber", {
                required: "This field is required.",
              })}
              onChange={(e) => {
                clearErrors("LicenseNumber");
                handleFormDataOther(e);
              }}
            />
            {error.LicenseNumber && (
              <span className="err-msg">{error?.LicenseNumber?.message}</span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Region<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="LicenseRegion"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelectCreate
                  defaultValue={
                    selectValues.brokerRegion.find(
                      (e) => e.id === getValues("LicenseRegion")
                    )
                      ? {
                        value: selectValues.brokerRegion.find(
                          (e) => e.id === getValues("LicenseRegion")
                        ).id,
                        label: selectValues.brokerRegion.find(
                          (e) => e.id === getValues("LicenseRegion")
                        )?.name,
                      }
                      : {
                        value: getValues("LicenseRegion"),
                        label: getValues("LicenseRegion"),
                      }
                  }
                  onChange={(data, action) => {
                    setValue("LicenseRegion", data.value);
                    handleFormDataOther({
                      target: {
                        value: data.value,
                        name: "LicenseRegion",
                      },
                    });

                    clearErrors("LicenseRegion");
                  }}
                  options={selectValues.brokerRegion.map((berth, index) => {
                    return { value: berth.id, label: berth.name };
                  })}
                />
              )}
            />
            {error.LicenseRegion && (
              <span className="err-msg">{error?.LicenseRegion?.message}</span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Province<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="LicenseProvince"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelectCreate
                  defaultValue={
                    selectValues.states.find(
                      (e) => e.id === getValues("LicenseProvince")
                    )
                      ? {
                        value: selectValues.states.find(
                          (e) => e.id === getValues("LicenseProvince")
                        ).id,
                        label: selectValues.states.find(
                          (e) => e.id === getValues("LicenseProvince")
                        )?.name,
                      }
                      : {
                        value: getValues("LicenseProvince"),
                        label: getValues("LicenseProvince"),
                      }
                  }
                  onChange={(data, action) => {
                    setValue("LicenseProvince", data.value);
                    handleFormDataOther({
                      target: {
                        value: data.value,
                        name: "LicenseProvince",
                      },
                    });

                    clearErrors("LicenseProvince");
                  }}
                  options={selectValues.states.map((berth, index) => {
                    return { value: berth.id, label: berth.name };
                  })}
                />
              )}
            />
            {error.LicenseProvince && (
              <span className="err-msg">{error?.LicenseProvince?.message}</span>
            )}
          </div>

          <div className="col-md-12">
            <span className="popup_input_label">
              License Type<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="LicenseType"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelectCreate
                  defaultValue={
                    selectValues.brokerLicenseTypeGeneral.find(
                      (e) => e.id === getValues("LicenseType")
                    )
                      ? {
                        value: selectValues.brokerLicenseTypeGeneral.find(
                          (e) => e.id === getValues("LicenseType")
                        ).id,
                        label: selectValues.brokerLicenseTypeGeneral.find(
                          (e) => e.id === getValues("LicenseType")
                        )?.name,
                      }
                      : {
                        value: getValues("LicenseType"),
                        label: getValues("LicenseType"),
                      }
                  }
                  onChange={(data, action) => {
                    setValue("LicenseType", data.value);
                    handleFormDataOther({
                      target: {
                        value: data.value,
                        name: "LicenseType",
                      },
                    });

                    clearErrors("LicenseType");
                  }}
                  // onChange={(selectedOption) => {
                  //   setValue("LicenseType", selectedOption?.value);
                  //   handleFormDataOther({
                  //     target: {
                  //       value: selectedOption.value,
                  //       name: "LicenseType",
                  //     },
                  //   });
                  //   clearErrors("LicenseType");
                  // }}
                  options={selectValues.brokerLicenseTypeGeneral.map(
                    (berth, index) => {
                      return { value: berth.id, label: berth.name };
                    }
                  )}
                />
              )}
            />
            {error.LicenseType && (
              <span className="err-msg">{error?.LicenseType?.message}</span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Sale Type<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="SaleType"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelectCreate
                  defaultValue={
                    selectValues.brokerQuotaSaleType.find(
                      (e) => e.id === getValues("SaleType")
                    )
                      ? {
                        value: selectValues.brokerQuotaSaleType.find(
                          (e) => e.id === getValues("SaleType")
                        ).id,
                        label: selectValues.brokerQuotaSaleType.find(
                          (e) => e.id === getValues("SaleType")
                        )?.name,
                      }
                      : {
                        value: getValues("SaleType"),
                        label: getValues("SaleType"),
                      }
                  }
                  onChange={(data, action) => {
                    setValue("SaleType", data.value);
                    handleFormDataOther({
                      target: {
                        value: data.value,
                        name: "SaleType",
                      },
                    });

                    clearErrors("SaleType");
                  }}
                  // onChange={(selectedOption) => {
                  //   setValue("LicenseType", selectedOption?.value);
                  //   handleFormDataOther({
                  //     target: {
                  //       value: selectedOption.value,
                  //       name: "LicenseType",
                  //     },
                  //   });
                  //   clearErrors("LicenseType");
                  // }}
                  options={selectValues.brokerQuotaSaleType.map(
                    (type, index) => {
                      return { value: type.id, label: type.name };
                    }
                  )}
                />
              )}
            />
            {error.LicenseType && (
              <span className="err-msg">{error?.LicenseType?.message}</span>
            )}
          </div>
          {/* <div className="col-md-12">
            <span className="popup_input_label">License Or Quota</span>
          </div>
          <div className="col-md-12">
            <select
              className="popup_input"
              id="license_or_quota"
              name="LicenseOrQuota"
              onChange={handleChangeOtherNormal}
            >
              <option value="9" selected>
                License
              </option>
              <option value="8" selected>
                Quota
              </option>
            </select>
          </div> */}
          {
            values?.ItemType !== 'Package' ?
              <>
                <div className="col-md-12">
                  <span className="popup_input_label">
                    Price<span className="required-field"></span>
                  </span>
                </div>
                <div className="col-md-12">
                  <input
                    type="number"
                    min="1000"
                    name="ItemPrice"
                    defaultValue={valuesOther?.ItemPrice}
                    className="popup_input"
                    {...register("ItemPrice", {
                      required: "This field is required.",
                      validate: {
                        maxNumber: (value) => {
                          if (value < 100) {
                            return "Minimum price should not be less than $100";
                          }
                        },
                      },
                      pattern: {
                        value: /^([1-9][0-9]*)$/,
                        message:
                          "Minimum Price should be numeric and greater than zero.",
                      },
                    })}
                    onChange={(e) => {
                      clearErrors("ItemPrice");
                      handleFormDataOther(e);
                    }}
                  />
                  <br></br>
                  {error.ItemPrice && (
                    <span className="err-msg">{error?.ItemPrice?.message}</span>
                  )}
                </div>
              </> : ''}
        </div>
      </form>
    </>
  );
};

const PleasureBoatListing = ({
  handleChangeOtherNormal,
  handleFormDataOther,
  valuesOther,
  values,
  selectValues,
  clearErrors,
  register,
  error,
  setValue,
  getValues,
  control,
}) => {
  return (
    <>
      <p className="good_day_para">
        Please fill the following details for Pleasure Boat
      </p>
      <form>
        <div className="row">
          <div className="col-md-12">
            <span className="popup_input_label">
              Boat Name<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <input
              type="text"
              name="PleasureBoatName"
              defaultValue={
                typeof valuesOther.PleasureBoatName === "string"
                  ? valuesOther.PleasureBoatName?.trimStart()
                  : valuesOther.PleasureBoatName
              }
              className="popup_input"
              id="PleasureBoatName"
              {...register("PleasureBoatName", {
                required: "This field is required",
              })}
              onChange={(e) => {
                clearErrors("PleasureBoatName");
                handleFormDataOther(e);
              }}
            />
            {error.PleasureBoatName && (
              <span className="err-msg">
                {error?.PleasureBoatName?.message}
              </span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              class<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="PleasureClass"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelectCreate
                  defaultValue={
                    selectValues.brokerPleasureClass.find(
                      (e) => e.id === getValues("PleasureClass")
                    )
                      ? {
                        value: selectValues.brokerPleasureClass.find(
                          (e) => e.id === getValues("PleasureClass")
                        ).id,
                        label: selectValues.brokerPleasureClass.find(
                          (e) => e.id === getValues("PleasureClass")
                        )?.name,
                      }
                      : (getValues("PleasureClass") === "other") ?
                        {
                          value: 'other', label: 'Other'
                        }
                        : {
                          value: getValues("PleasureClass"),
                          label: getValues("PleasureClass"),
                        }
                  }
                  onChange={(data, action) => {
                    setValue("PleasureClass", data.value);
                    handleFormDataOther({
                      target: {
                        value: data.value,
                        name: "PleasureClass",
                      },
                    });

                    clearErrors("PleasureClass");
                  }}

                  options={[
                    ...selectValues.brokerPleasureClass.map((berth) => ({
                      value: berth.id,
                      label: berth.name,
                    })),
                    { value: 'other', label: 'Other' }, // Add the custom option here
                  ]}
                // options={selectValues.brokerPleasureClass.map(
                //   (berth, index) => {
                //     return { value: berth.id, label: berth.name };
                //   }
                // )}
                />
              )}
            />
            {
              valuesOther?.PleasureClass === "other" ?
                <input type="text" name="PleasureClassOther" class="popup_input" placeholder="Enter Pleasure Class" defaultValue={valuesOther.PleasureClassOther}
                  {...register("PleasureClassOther")} onChange={(e) => { handleFormDataOther(e); }}></input>
                : ""}
            {error.PleasureClass && (
              <span className="err-msg">{error?.PleasureClass?.message}</span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Category<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="PleasureCategory"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelectCreate
                  defaultValue={
                    selectValues.brokerPleasureCategory.find(
                      (e) => e.id === getValues("PleasureCategory")
                    )
                      ? {
                        value: selectValues.brokerPleasureCategory.find(
                          (e) => e.id === getValues("PleasureCategory")
                        ).id,
                        label: selectValues.brokerPleasureCategory.find(
                          (e) => e.id === getValues("PleasureCategory")
                        )?.name,
                      }
                      : {
                        value: getValues("PleasureCategory"),
                        label: getValues("PleasureCategory"),
                      }
                  }
                  onChange={(data, action) => {
                    setValue("PleasureCategory", data.value);
                    handleFormDataOther({
                      target: {
                        value: data.value,
                        name: "PleasureCategory",
                      },
                    });

                    clearErrors("PleasureCategory");
                  }}
                  options={selectValues.brokerPleasureCategory.map(
                    (berth, index) => {
                      return { value: berth.id, label: berth.name };
                    }
                  )}
                />
              )}
            />
            {error.PleasureCategory && (
              <span className="err-msg">
                {error?.PleasureCategory?.message}
              </span>
            )}
          </div>
          {
            values?.ItemType !== 'Package' ?
              <>
                <div className="col-md-12">
                  <span className="popup_input_label">
                    Price<span className="required-field"></span>
                  </span>
                </div>
                <div className="col-md-12">
                  <input
                    type="number"
                    min="1000"
                    name="ItemPrice"
                    defaultValue={valuesOther.ItemPrice}
                    className="popup_input"
                    id="ItemPrice"
                    {...register("ItemPrice", {
                      required: "This field is required.",
                      valueAsNumber: true,
                      validate: {
                        required: (value) => {
                          if (parseFloat(value) <= 100) {
                            return "Price should not be less than $100";
                          }
                        },
                      },
                      pattern: {
                        value: /^([1-9][0-9]*)$/,
                        message: "Price should be numeric and greater than zero.",
                      },
                    })}
                    onChange={(e) => {
                      clearErrors("ItemPrice");
                      handleFormDataOther(e);
                    }}
                  />
                  {error.ItemPrice && (
                    <span className="err-msg">{error?.ItemPrice?.message}</span>
                  )}
                </div>
              </> : ''}
        </div>
      </form>
    </>
  );
};
const PackageListing = ({
  handleChangeOtherNormal,
  handleCustomButtonSelect,
  handleFormDataOther,
  valuesOther,
  values,
  selectValues,
  clearErrors,
  register,
  error,
  btnError,
  setValue,
  getValues,
  control,
  handleFormData
}) => {
  console.log('values_packateitem_data', values?.PackageItemType)
  return (
    <>
      {(values?.PackageItemType === "License") ? (
        <LicenseListing
          handleChangeOtherNormal={handleChangeOtherNormal}
          control={control}
          handleFormDataOther={handleFormDataOther}
          valuesOther={valuesOther}
          values={values}
          selectValues={selectValues}
          register={register}
          error={error}
          clearErrors={clearErrors}
          setValue={setValue}
          getValues={getValues}
        />
      ) : (values?.PackageItemType === "Quota") ? (
        <QuotaListing
          handleChangeOtherNormal={handleChangeOtherNormal}
          control={control}
          handleFormDataOther={handleFormDataOther}
          valuesOther={valuesOther}
          values={values}
          selectValues={selectValues}
          register={register}
          error={error}
          clearErrors={clearErrors}
          setValue={setValue}
          getValues={getValues}
        />
      ) : values?.PackageItemType === "Package" ? (
        <PackageListing
          handleCustomButtonSelect={handleCustomButtonSelect}
          handleChangeOtherNormal={handleChangeOtherNormal}
          control={control}
          handleFormDataOther={handleFormDataOther}
          valuesOther={valuesOther}
          values={values}
          selectValues={selectValues}
          register={register}
          error={error}
          btnError={btnError}
          clearErrors={clearErrors}
          setValue={setValue}
          getValues={getValues}
        />
      ) : values?.PackageItemType === "Equipment" ? (
        <EquipmentListing
          setValue={setValue}
          getValues={getValues}
          control={control}
          handleChangeOtherNormal={handleChangeOtherNormal}
          handleFormDataOther={handleFormDataOther}
          values={values}
          valuesOther={valuesOther}
          selectValues={selectValues}
          register={register}
          error={error}
          clearErrors={clearErrors}
        />
      ) : values?.PackageItemType === "Seafood" ? (
        <SeaFoodListing
          handleChangeOtherNormal={handleChangeOtherNormal}
          setValue={setValue}
          getValues={getValues}
          control={control}
          handleFormDataOther={handleFormDataOther}
          valuesOther={valuesOther}
          values={values}
          selectValues={selectValues}
          register={register}
          error={error}
          clearErrors={clearErrors}
        />
      ) : values?.PackageItemType === "Pleasure Boat" ? (
        <PleasureBoatListing
          handleChangeOtherNormal={handleChangeOtherNormal}
          setValue={setValue}
          getValues={getValues}
          control={control}
          handleFormDataOther={handleFormDataOther}
          valuesOther={valuesOther}
          values={values}
          selectValues={selectValues}
          register={register}
          error={error}
          clearErrors={clearErrors}
        />
      ) : values?.PackageItemType === "Boat" ? (
        <>
          <p className="good_day_para">
            Please Advise Atleast One Of The Following
          </p>
          <div className="row">
            <div className="col-md-12">
              <span className="popup_input_label">
                Please Select Boat Type:
              </span>
            </div>
            <div className="col-md-12">
              <Controller
                name="VesselType"
                control={control}
                defaultValue=""
                rules={{ required: "This field is required" }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <SearchSelect
                    onChange={(selectedOption) => {
                      setValue("VesselType", selectedOption?.value);
                      handleFormData({
                        target: {
                          value: selectedOption?.value,
                          name: "VesselType",
                        },
                      });
                      clearErrors("VesselType");
                    }}
                    defaultValue={
                      selectValues.vesselType.find(
                        (e) => e.id === getValues("VesselType")
                      )
                        ? {
                          value: selectValues.vesselType.find(
                            (e) => e.id === getValues("VesselType")
                          ).id,
                          label: selectValues.vesselType.find(
                            (e) => e.id === getValues("VesselType")
                          )?.name,
                        }
                        : (getValues("VesselType") === "other") ?
                          {
                            value: 'other', label: 'Other'
                          } : ''
                    }

                    options={[
                      ...selectValues.vesselType.map((vesseltype) => ({
                        value: vesseltype.id,
                        label: vesseltype.name,
                      })),
                      { value: 'other', label: 'Other' }, // Add the custom option here
                    ]}
                  />
                )}
              />
              {
                values?.VesselType === "other" ?
                  <input type="text" name="VesselTypeOther" class="popup_input" placeholder="Enter Boat Type" defaultValue={values.VesselTypeOther}
                    {...register("VesselTypeOther")} onChange={(e) => {
                      handleFormData(e);
                    }}></input>
                  : ""}
              {error.VesselType && (
                <span className="err-msg">{error.VesselType.message}</span>
              )}
            </div>

            <div className="col-md-12">
              <span className="popup_input_label">
                Official Number(Ship Reg.):
              </span>
            </div>
            <div className="col-md-12">
              <input
                type="text"
                name="OfficialNumber"
                className="popup_input"
                defaultValue={values.OfficialNumber}
                placeholder="Ex : IMO 8814275"
                {...register("OfficialNumber")}
                onChange={(e) => {
                  clearErrors("OfficialNumber");
                  handleFormData(e);
                }} />
            </div>
            <div className="col-md-12">
              <span className="popup_input_label">
                Name Of Vessel:<span className="required-field"></span>
              </span>
            </div>
            <div className="col-md-12">
              <input
                type="text"
                name=""
                className="popup_input"
                defaultValue={values.VesselName}
                placeholder="Ex : Fiberglass"
                {...register("VesselName", {
                  required: "This field is required.",
                })}
                onChange={(e) => {
                  clearErrors("VesselName");
                  handleFormData(e);
                }}
              />
              {error.VesselName && (
                <span className="err-msg">{error.VesselName.message}</span>
              )}
            </div>
            <div className="col-md-12">
              <span className="popup_input_label">
                Vessel Registration Number:
              </span>
            </div>
            <div className="col-md-12">
              <input
                type="text"
                name="VesselRegNum"
                defaultValue={values.VesselRegNum}
                id="usr"
                className="popup_input"
                placeholder="Ex : IMO 8814275"
                {...register("VesselRegNum", {})}
                onChange={(e) => {
                  clearErrors("VesselRegNum");
                  handleFormData(e);
                }} />
            </div>
          </div>
        </>
      ) : (
        <div className="step well ">
          <p className="good_day_para">Select the Assets to Include in Your Package (Complete Package/Enterprise/License Package)</p>
          <p>You may choose one or more assets from the options below. After entering the details for each asset, you will return to this screen to select additional assets if needed. Continue adding assets until your complete package/enterprise/license package is finalized</p>
          <form>
            <div className="button-wrap">
              <input className="hidden radio-label" type="radio" name="PackageItemType" value="Boat" id="package1-button" checked={values.PackageItemType === 'Boat' ? 'checked' : ''} onClick={handleCustomButtonSelect} />
              <label className="button-label" for="package1-button">
                <h1>Vessel</h1>
              </label>
              <input className="hidden radio-label" type="radio" name="PackageItemType" value="License" id="package2-button" checked={values.PackageItemType === 'License' ? 'checked' : ''} onClick={handleCustomButtonSelect} />
              <label className="button-label" for="package2-button">
                <h1>License</h1>
              </label>

              <input className="hidden radio-label" type="radio" name="PackageItemType" value="Quota" id="package3-button" checked={values.PackageItemType === 'Quota' ? 'checked' : ''} onClick={handleCustomButtonSelect} />
              <label className="button-label" for="package3-button">
                <h1>Quota</h1>
              </label>
              <input className="hidden radio-label" type="radio" name="PackageItemType" value="Equipment" id="package5-button" checked={values.PackageItemType === 'Equipment' ? 'checked' : ''} onClick={handleCustomButtonSelect} />
              <label className="button-label" for="package5-button">
                <h1>Equipment</h1>
              </label>
              <input className="hidden radio-label" type="radio" name="PackageItemType" value="Seafood" id="package6-button" checked={values.PackageItemType === 'Seafood' ? 'checked' : ''} onClick={handleCustomButtonSelect} />
              <label className="button-label" for="package6-button">
                <h1>Seafood</h1>
              </label>
            </div>
            {btnError && <p className='err-msg'>Please select one of above</p>}
          </form>
        </div>
      )}

    </>
  );
};

const EquipmentListing = ({
  handleChangeOtherNormal,
  handleFormDataOther,
  values,
  valuesOther,
  selectValues,
  register,
  error,
  clearErrors,
  setValue,
  getValues,
  control,
}) => {
  var current_year = new Date().getFullYear(); // variable for get the current year value
  console.log('valuesOther', values)
  return (
    <>
      <p className="good_day_para">
        Please fill the following details for Equipment/Gear
      </p>
      <form>
        <div className="row">
          <div className="col-md-12">
            <span className="popup_input_label">
              Gear Model<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <input
              type="text"
              min="0"
              name="EquipmentModel"
              defaultValue={valuesOther.EquipmentModel}
              className="popup_input"
              id="EquipmentModel"
              {...register("EquipmentModel", {
                required: "This field is required.",
              })}
              onChange={(e) => {
                clearErrors("EquipmentModel");
                handleFormDataOther(e);
              }}
            />
            {error?.EquipmentModel && (
              <span className="err-msg">{error?.EquipmentModel?.message}</span>
            )}
          </div>

          <div className="col-md-12">
            <span className="popup_input_label">
              Serial number<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <input
              type="text"
              onKeyPress={(event) => {
                if (event.charCode < 48) {
                  event.preventDefault();
                }
              }}
              min="0"
              name="EquipmentSerialNumber"
              defaultValue={valuesOther.EquipmentSerialNumber}
              className="popup_input"
              id="EquipmentSerialNumber"
              {...register("EquipmentSerialNumber", {
                required: "This field is required.",
              })}
              onChange={(e) => {
                clearErrors("EquipmentSerialNumber");
                handleFormDataOther(e);
              }}
            />
            {error.EquipmentSerialNumber && (
              <span className="err-msg">
                {error.EquipmentSerialNumber.message}
              </span>
            )}
          </div>

          <div className="col-md-12">
            <span className="popup_input_label">
              Manufacturer<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <input
              type=""
              name="EquipmentManufacturer"
              defaultValue={valuesOther.EquipmentManufacturer}
              className="popup_input"
              id="EquipmentManufacturer"
              {...register("EquipmentManufacturer", {
                required: "This field is required.",
              })}
              onChange={(e) => {
                clearErrors("EquipmentManufacturer");
                handleFormDataOther(e);
              }}
            />
            {error.EquipmentManufacturer && (
              <span className="err-msg">
                {error.EquipmentManufacturer.message}
              </span>
            )}
          </div>

          <div className="col-md-12">
            <span className="popup_input_label">
              Category<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="EquipmentCategory"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelectCreate
                  defaultValue={
                    selectValues.brokerEquipmentCategory.find(
                      (e) => e.id === getValues("EquipmentCategory")
                    )
                      ? {
                        value: selectValues.brokerEquipmentCategory.find(
                          (e) => e.id === getValues("EquipmentCategory")
                        ).id,
                        label: selectValues.brokerEquipmentCategory.find(
                          (e) => e.id === getValues("EquipmentCategory")
                        )?.name,
                      }
                      : {
                        value: getValues("EquipmentCategory"),
                        label: getValues("EquipmentCategory"),
                      }
                  }
                  onChange={(data, action) => {
                    setValue("EquipmentCategory", data.value);
                    handleFormDataOther({
                      target: {
                        value: data.value,
                        name: "EquipmentCategory",
                      },
                    });

                    clearErrors("EquipmentCategory");
                  }}
                  options={selectValues.brokerEquipmentCategory.map(
                    (berth, index) => {
                      return { value: berth.id, label: berth.name };
                    }
                  )}
                />
              )}
            />
            {error.EquipmentCategory && (
              <span className="err-msg">{error.EquipmentCategory.message}</span>
            )}
          </div>

          <div className="col-md-12">
            <span className="popup_input_label">
              Product Type<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="EquipmentProduct"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelectCreate
                  defaultValue={
                    selectValues.brokerEquipmentProduct.find(
                      (e) => e.id === getValues("EquipmentProduct")
                    )
                      ? {
                        value: selectValues.brokerEquipmentProduct.find(
                          (e) => e.id === getValues("EquipmentProduct")
                        ).id,
                        label: selectValues.brokerEquipmentProduct.find(
                          (e) => e.id === getValues("EquipmentProduct")
                        )?.name,
                      }
                      : {
                        value: getValues("EquipmentProduct"),
                        label: getValues("EquipmentProduct"),
                      }
                  }
                  onChange={(data, action) => {
                    setValue("EquipmentProduct", data.value);
                    handleFormDataOther({
                      target: {
                        value: data.value,
                        name: "EquipmentProduct",
                      },
                    });

                    clearErrors("EquipmentProduct");
                  }}
                  options={selectValues.brokerEquipmentProduct.map(
                    (berth, index) => {
                      return { value: berth.id, label: berth.name };
                    }
                  )}
                />
              )}
            />
            {error.EquipmentProduct && (
              <span className="err-msg">{error.EquipmentProduct.message}</span>
            )}
          </div>

          <div className="col-md-12">
            <span className="popup_input_label">
              Year<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <input
              type="number"
              defaultValue={valuesOther?.EquipmentYear}
              className="popup_input"
              id="EquipmentYear"
              name="EquipmentYear"
              {...register("EquipmentYear", {
                required: "This field is required.",
                pattern: {
                  value: /^([1-9][0-9]{3})+$/,
                  message: "Please enter valid year.",
                },
                validate: {
                  maxNumber: (value) => {
                    if (valuesOther?.EquipmentYear <= 1920) {
                      return "Year should be in range 1920 to current year";
                    } else if (value > current_year) {
                      return "Year should not be greater than current year";
                    }
                  },
                },
              })}
              onChange={(e) => {
                clearErrors("EquipmentYear");
                handleFormDataOther(e);
              }}
            />

            <span className="err-msg">{error?.EquipmentYear?.message}</span>
          </div>

          {values?.ItemType !== 'Package' ?
            <>
              <div className="col-md-12">
                <span className="popup_input_label">
                  Price<span className="required-field"></span>
                </span>
              </div>
              <div className="col-md-12">
                <input
                  type="number"
                  min="1000"
                  name="ItemPrice"
                  defaultValue={valuesOther.ItemPrice}
                  className="popup_input"
                  id="ItemPrice"
                  {...register("ItemPrice", {
                    required: "This field is required.",
                    validate: {
                      maxNumber: (value) => {
                        if (value < 100) {
                          return "Maximum price should not be less than $100";
                        }
                      },
                    },
                    pattern: {
                      value: /^([1-9][0-9]*)$/,
                      message:
                        "Maximum Price should be numeric and greater than zero.",
                    },
                  })}
                  onChange={(e) => {
                    clearErrors("ItemPrice");
                    handleFormDataOther(e);
                  }}
                />
                {error.ItemPrice && (
                  <span className="err-msg">{error.ItemPrice.message}</span>
                )}
              </div>
            </> : ''}
        </div>
      </form>
    </>
  );
};

const SeaFoodListing = ({
  handleChangeOtherNormal,
  handleFormDataOther,
  valuesOther,
  values,
  selectValues,
  register,
  error,
  clearErrors,
  setValue,
  getValues,
  control,
}) => {
  return (
    <>
      <p className="good_day_para">
        Please fill the following details for Seafood
      </p>
      <form>
        <div className="row">
          <div className="col-md-12">
            <span className="popup_input_label">
              Species<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="SeaFoodSpecies"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelectCreate
                  defaultValue={
                    selectValues.brokerGeneralSpecies.find(
                      (e) => e.id === getValues("SeaFoodSpecies")
                    )
                      ? {
                        value: selectValues.brokerGeneralSpecies.find(
                          (e) => e.id === getValues("SeaFoodSpecies")
                        ).id,
                        label: selectValues.brokerGeneralSpecies.find(
                          (e) => e.id === getValues("SeaFoodSpecies")
                        )?.name,
                      }
                      : {
                        value: getValues("SeaFoodSpecies"),
                        label: getValues("SeaFoodSpecies"),
                      }
                  }
                  onChange={(data, action) => {
                    setValue("SeaFoodSpecies", data.value);
                    handleFormDataOther({
                      target: {
                        value: data.value,
                        name: "SeaFoodSpecies",
                      },
                    });

                    clearErrors("SeaFoodSpecies");
                  }}
                  options={selectValues.brokerGeneralSpecies.map(
                    (berth, index) => {
                      return { value: berth.id, label: berth.name };
                    }
                  )}
                />
              )}
            />
            {error.SeaFoodSpecies && (
              <span className="err-msg">{error.SeaFoodSpecies.message}</span>
            )}
          </div>

          <div className="col-md-12">
            <span className="popup_input_label">
              Size<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="SeaFoodSize"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelectCreate
                  defaultValue={
                    selectValues.brokerGeneralSpeciesSize.find(
                      (e) => e.id === getValues("SeaFoodSize")
                    )
                      ? {
                        value: selectValues.brokerGeneralSpeciesSize.find(
                          (e) => e.id === getValues("SeaFoodSize")
                        ).id,
                        label: selectValues.brokerGeneralSpeciesSize.find(
                          (e) => e.id === getValues("SeaFoodSize")
                        )?.name,
                      }
                      : {
                        value: getValues("SeaFoodSize"),
                        label: getValues("SeaFoodSize"),
                      }
                  }
                  onChange={(data, action) => {
                    setValue("SeaFoodSize", data.value);
                    handleFormDataOther({
                      target: {
                        value: data.value,
                        name: "SeaFoodSize",
                      },
                    });

                    clearErrors("SeaFoodSize");
                  }}
                  options={selectValues.brokerGeneralSpeciesSize.map(
                    (berth, index) => {
                      return { value: berth.id, label: berth.name };
                    }
                  )}
                />
              )}
            />
            {error.SeaFoodSize && (
              <span className="err-msg">{error.SeaFoodSize.message}</span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Quantity<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <input
              type="text"
              name="SeaFoodQuantity"
              defaultValue={valuesOther.SeaFoodQuantity}
              className="popup_input"
              id="SeaFoodQuantity"
              {...register("SeaFoodQuantity", {
                required: "This field is required.",
                validate: {
                  required: (value) => {
                    if (parseFloat(value) <= 0)
                      return "Quantity should be greater than zero.";
                  },
                },
              })}
              onChange={(e) => {
                clearErrors("SeaFoodQuantity");
                handleFormDataOther(e);
              }}
            />
            {error.SeaFoodQuantity && (
              <span className="err-msg">{error.SeaFoodQuantity.message}</span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">Description</span>
          </div>
          <div className="col-md-12">
            <input
              type="text"
              name="SeaFoodDescription"
              defaultValue={valuesOther.SeaFoodDescription}
              className="popup_input"
              id="SeaFoodDescription"
              onChange={handleChangeOtherNormal}
            />
          </div>
          {values?.ItemType !== "Package" ?
            <>
              <div className="col-md-12">
                <span className="popup_input_label">
                  Price<span className="required-field"></span>
                </span>
              </div>
              <div className="col-md-12">
                <input
                  type="number"
                  min="1000"
                  name="ItemPrice"
                  defaultValue={valuesOther.ItemPrice}
                  className="popup_input"
                  id="ItemPrice"
                  {...register("ItemPrice", {
                    required: "This field is required.",
                    validate: {
                      maxNumber: (value) => {
                        if (value < 100) {
                          return "Maximum price should not be less than $100";
                        }
                      },
                    },
                    pattern: {
                      value: /^([1-9][0-9]*)$/,
                      message:
                        "Maximum Price should be numeric and greater than zero.",
                    },
                  })}
                  onChange={(e) => {
                    clearErrors("ItemPrice");
                    handleFormDataOther(e);
                  }}
                />
                {error.ItemPrice && (
                  <span className="err-msg">{error.ItemPrice.message}</span>
                )}
              </div>
            </>
            : ''}
        </div>
      </form>
    </>
  );
};

const Step3 = ({
  handleFormData,
  handleCustomButtonSelect,
  handleChangeOtherNormal,
  handleChangeNormal,
  values,
  handleFormDataOther,
  valuesOther,
  selectValues,
  register,
  error,
  btnError,
  clearErrors,
  setValue,
  getValues,
  control,
}) => {
  console.log('step', values.ItemType)
  return (
    <>
      {(values.ItemType === "License") ? (
        <LicenseListing
          handleChangeOtherNormal={handleChangeOtherNormal}
          control={control}
          handleFormDataOther={handleFormDataOther}
          valuesOther={valuesOther}
          selectValues={selectValues}
          register={register}
          error={error}
          clearErrors={clearErrors}
          setValue={setValue}
          getValues={getValues}
        />
      ) : (values.ItemType === "Quota") ? (
        <QuotaListing
          handleChangeOtherNormal={handleChangeOtherNormal}
          control={control}
          handleFormDataOther={handleFormDataOther}
          valuesOther={valuesOther}
          selectValues={selectValues}
          register={register}
          error={error}
          clearErrors={clearErrors}
          setValue={setValue}
          getValues={getValues}
        />
      ) : values.ItemType === "Package" ? (
        <PackageListing
          handleChangeOtherNormal={handleChangeOtherNormal}
          handleCustomButtonSelect={handleCustomButtonSelect}
          control={control}
          handleFormDataOther={handleFormDataOther}
          valuesOther={valuesOther}
          values={values}
          selectValues={selectValues}
          register={register}
          error={error}
          btnError={btnError}
          clearErrors={clearErrors}
          setValue={setValue}
          getValues={getValues}
          handleFormData={handleFormData}
        />
      ) : values.ItemType === "Equipment" ? (
        <EquipmentListing
          setValue={setValue}
          getValues={getValues}
          control={control}
          handleChangeOtherNormal={handleChangeOtherNormal}
          handleFormDataOther={handleFormDataOther}
          valuesOther={valuesOther}
          selectValues={selectValues}
          register={register}
          error={error}
          clearErrors={clearErrors}
        />
      ) : values.ItemType === "Seafood" ? (
        <SeaFoodListing
          handleChangeOtherNormal={handleChangeOtherNormal}
          setValue={setValue}
          getValues={getValues}
          control={control}
          handleFormDataOther={handleFormDataOther}
          valuesOther={valuesOther}
          selectValues={selectValues}
          register={register}
          error={error}
          clearErrors={clearErrors}
        />
      ) : values.ItemType === "Pleasure Boat" ? (
        <PleasureBoatListing
          handleChangeOtherNormal={handleChangeOtherNormal}
          setValue={setValue}
          getValues={getValues}
          control={control}
          handleFormDataOther={handleFormDataOther}
          valuesOther={valuesOther}
          values={values}
          selectValues={selectValues}
          register={register}
          error={error}
          clearErrors={clearErrors}
        />
      ) : values.ItemType === "Boat" ? (
        <>
          <p className="good_day_para">
            Please Advise Atleast One Of The Following
          </p>
          <div className="row">
            <div className="col-md-12">
              <span className="popup_input_label">
                Please Select Boat Type:
              </span>
            </div>
            <div className="col-md-12">
              <Controller
                name="VesselType"
                control={control}
                defaultValue=""
                rules={{ required: "This field is required" }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <SearchSelect
                    onChange={(selectedOption) => {
                      setValue("VesselType", selectedOption?.value);
                      handleFormData({
                        target: {
                          value: selectedOption?.value,
                          name: "VesselType",
                        },
                      });
                      clearErrors("VesselType");
                    }}
                    defaultValue={
                      selectValues.vesselType.find(
                        (e) => e.id === getValues("VesselType")
                      )
                        ? {
                          value: selectValues.vesselType.find(
                            (e) => e.id === getValues("VesselType")
                          ).id,
                          label: selectValues.vesselType.find(
                            (e) => e.id === getValues("VesselType")
                          )?.name,
                        }
                        : (getValues("VesselType") === "other") ?
                          {
                            value: 'other', label: 'Other'
                          } : ''
                    }

                    options={[
                      ...selectValues.vesselType.map((vesseltype) => ({
                        value: vesseltype.id,
                        label: vesseltype.name,
                      })),
                      { value: 'other', label: 'Other' }, // Add the custom option here
                    ]}
                  />
                )}
              />
              {
                values?.VesselType === "other" ?
                  <input type="text" name="VesselTypeOther" class="popup_input" placeholder="Enter Boat Type" defaultValue={values.VesselTypeOther}
                    {...register("VesselTypeOther")} onChange={handleChangeNormal}></input>
                  : ""}
              {error.VesselType && (
                <span className="err-msg">{error.VesselType.message}</span>
              )}
            </div>
            <div className="col-md-12">
              <span className="popup_input_label">
                Official Number(Ship Reg.):
              </span>
            </div>
            <div className="col-md-12">
              <input
                type="text"
                name="OfficialNumber"
                className="popup_input"
                defaultValue={values.OfficialNumber}
                placeholder="Ex : IMO 8814275"
                {...register("OfficialNumber")}
                onChange={handleChangeNormal}
              />
            </div>
            <div className="col-md-12">
              <span className="popup_input_label">
                Name Of Vessel:<span className="required-field"></span>
              </span>
            </div>
            <div className="col-md-12">
              <input
                type="text"
                name=""
                className="popup_input"
                defaultValue={values.VesselName}
                placeholder="Ex : Fiberglass"
                {...register("VesselName", {
                  required: "This field is required.",
                })}
                onChange={(e) => {
                  clearErrors("VesselName");
                  handleFormData(e);
                }}
              />
              {error.VesselName && (
                <span className="err-msg">{error.VesselName.message}</span>
              )}
            </div>
            <div className="col-md-12">
              <span className="popup_input_label">
                Vessel Registration Number:
              </span>
            </div>
            <div className="col-md-12">
              <input
                type="text"
                name="VesselRegNum"
                defaultValue={values.VesselRegNum}
                id="usr"
                className="popup_input"
                placeholder="Ex : IMO 8814275"
                {...register("VesselRegNum", {})}
                onChange={handleChangeNormal}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="step well ">
          <div className="midBoxBut">
            <h3 style={{ color: "red" }}>Please select an option</h3>
          </div>
        </div>
      )}
    </>
  );
};

export default Step3;
