import { Url } from "../api_settings/api_constants";
import { wrappedFetch, wrappedGet } from "../api_settings/urlFunctions";


// get jobs object data
export const getJobs = (data) => {
    //    return wrappedGet(Url.GET_JOBS, "GET");
    return wrappedFetch(Url.GET_JOBS, "POST", data);
};

// post job object data
export const postJob = (data) => {
    //    return wrappedGet(Url.GET_JOBS, "GET");
    return wrappedFetch(Url.POST_JOB, "POST", data);
};

// search job object data
export const searchJob = (data) => {
    //    return wrappedGet(Url.GET_JOBS, "GET");
    return wrappedFetch(Url.SEARCH_JOB, "POST", data);
};

// filter job post object data
export const filterJobs = (data, id) => {
    //    return wrappedGet(Url.GET_JOBS, "GET");
    return wrappedFetch(Url.Filter_JOBS + id, "POST", data);
};

export const getJob = (data) => {
    //    return wrappedGet(Url.GET_JOBS, "GET");
    return wrappedFetch(Url.GET_JOB, "POST", data);
};

export const updateJobStatus = (data) => {
    //    return wrappedGet(Url.GET_JOBS, "GET");
    return wrappedFetch(Url.UPDATE_JOB_STATUS, "POST", data);
};

export const getUserJob = (data, id) => {
    return wrappedFetch(Url.GET_USER_JOB + id, "POST", data);
};

export const viewEmailPhone = (data) => {
    return wrappedFetch(Url.VIEW_EMAIL_PHONE, "POST", data);
};