/* eslint-disable jsx-a11y/anchor-is-valid */
import { Avatar } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import {
  AVATAR,
  AVATAR2,
  AVATARDROP,
  HELP,
  SELL,
  BUY,
  CLOSEICON,
  NOVILOGO,
} from "../../constants/imagepath";

import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../store/userSlice";
import { LOGO } from "../../constants/imagepath";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "../../components/Listing/listing.css";
import { listingActions } from "../../store/listingSlice";
import { referralListingActions } from "../../store/referralListingSlice";
import { getUserPackage } from "../../api_services/api_calls/memberShipApis";

import "../../assets/css/style_2.css";
import "../../assets/css/animate.min.css";
import "../../assets/css/responsive_2.css";
import "../../assets/css/style.css";
import "../../assets/css/color.css";
import "../../assets/css/bootstrap.css";
import SellerListing from "../Listing/SellerListing";
import BuyerListing from "../Listing/BuyerListing";
import RefferalAgentListing from "../Listing/RefferalAgentListing";
import RefferalAgentRequest from "../Listing/RefferalAgentRequest";

// import SearchFilter from "../../screens/test-dashboard/components/SearchFilter";
import SearchFilterMobile from "../../screens/test-dashboard/components/SearchFilterMobile";
import InformationModal from "../Modals/InformationModal";
import BotpresssContainer from "../BotpressContainer";
import job from "../job";
// const LoginModal = lazy(() => import("../screens/LoginModal"));
// const PaymentInfoModal = lazy(() => import("./PaymentInfoModal"));
// const RefferalAgentListing = lazy(() =>
//   import("../Listing/RefferalAgentListing")
// );
// const RefferalAgentRequest = lazy(() =>
//   import("../Listing/RefferalAgentRequest")
// );

//import { CurrentUserContext } from "../context";

const AfterLoginHeader = ({ headerCallback, userData, dashboardFunc }) => {
  // states for manage component data
  // const { currentUser } = useContext(CurrentUserContext);
  // const [showLoginModal, setShowLoginModal] = useState(false);
  // const [paymentModal, setPaymentModal] = useState(false);
  // const [threeDigitCode, setThreeDigitCode] = useState("");
  // const [cardHolderName, setCardHolderName] = useState("");
  // const [code, setCode] = useState("");
  const history = useHistory();
  const [userProfile, setUserProfile] = useState(false);
  const [userMembership, setUserMembership] = useState("");
  const [sellerListing, setSellerListing] = useState(false);
  const [refferalListing, setRefferalListing] = useState(false);
  const [sellerQuestion, setSellerQuestion] = useState(false);
  const [buyerQuestion, setBuyerQuestion] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [refferalRequest, setRefferalRequest] = useState(false);
  const [buyerRequest, setBuyerRequest] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [botspressShow, setBotspressShow] = useState(false);

  const dispatch = useDispatch(); // function for dispatch The action

  /******* Calling Dynamic URL ********/

  useEffect(() => {
    //Google Translate Initialize
    var addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
    // "This function will fetch the data of user profile when user first visits on page"
    let response = userData;
    if (response && response.data.profile.profile_status === true) {
      setUserProfile(true);
      setUserDetails(response.data);
      if (response.data.user.has_membership === true) {
        setUserMembership(response.data.user.package.name);
      }
    }
    if (headerCallback) {
      headerCallback(response.data);
    }
    // eslint-disable-next-line
  }, []);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        includedLanguages: 'en,fr', // include this for selected languages
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
      },
      'google_translate_element'
    );
  };


  // const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    //  "handle using for open the togglwe"
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    // "Ths function is works when user clicks away from popup "
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleChange(data) {
    // "This function is using for close the popup on onClick  of X icon"
    setSellerQuestion(data);
    setRefferalListing(data);
    setSellerListing(data);
    setBuyerQuestion(data);
    setBuyerRequest(data);
    setRefferalRequest(data);
  }

  function setListingOption(option) {
    // "This function we are using for open popup on referral side on click of seller button when buyer is false"
    setSellerQuestion(false);
    if (option === true) {
      setRefferalListing(true);
    } else {
      setSellerListing(true);
    }
  }

  function setReferralOption(option) {
    // "This function we are using for open popup on referral side on click of seller button when buyer is false"
    setBuyerQuestion(false);
    if (option === true) {
      setRefferalRequest(true);
    } else {
      setBuyerRequest(true);
    }
  }

  /*const logout = (event) => {
         axios.get(apiUrl+'logout', { headers: { 'Accept': 'application/json', "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        .then(response => {
          if (response.data.status === true) {
            localStorage.clear();
          }
  
        });
  
      history.push('/')
      setOpen(false);
  
    };
  */
  const logoutUser = () => {
    // "Function for logout the user it will triggered when user clicks on logout button"
    var chat_widget = document.getElementById("cometchat__widget");
    const bp_chat_widget = document.getElementById("bp-web-widget-container");
    if(bp_chat_widget){
      document.getElementById("bp-web-widget-container").remove();
    }
    if (chat_widget) {
      window.CometChatWidget.logout().then((response) => {
        // User successfully logged out.
        // Perform any clean up if required.
        document.getElementById("cometchat__widget").remove();
        dispatch(logout());
      });
    } else {
      dispatch(logout());
    }
    setOpen(false);
  };

  const updateProfile = () => {
    // "Handle for redirect user to update user profile page will triggered when user clicks on profile button"
    if (userProfile) {
      history.push("/profile");
    } else {
      history.push("/userDetails");
    }
  };

  function handleListKeyDown(event) {
    // "Function for down the focus on click of tab button from keyboard"
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // function setActiveLink(event) {
  //   // "Using this function to redirect user to items for sale page it will triggered on on Click of "Items for sale button""
  //   console.log(event);
  // }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    // " this function is using for focus on previous open"
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
    // eslint-disable-next-line
  }, [open]);

  function resetDefaultFilter() {
    // "Function for reset All the filters data this function will trigered when user clicks on website main logo"
    localStorage.removeItem("searchfilter");
    localStorage.removeItem("sessionTimes");
    localStorage.removeItem("Requestsearchfilter");
    localStorage.removeItem("RequestsessionTimes");
    localStorage.removeItem("Request_searchfilter");
    localStorage.removeItem("Request_sessionTimes");

    history.push("/dashboard");
    window.location.reload(true);
  }

  /******* Launch Chat Bot ********/

  const launchChat = (e) => {
    // Handle for launch  chat on click of "sell Button"
    // dashboard_chat
    if (e.target.id === "Buy") setBuyerQuestion(true);
    // triggers click event on chat
  };

  const buyLaunchChat = (e) => {
    // Handle for launch  chat on click of "Buy Button"
    // dashboard_chat
    if (e.target.id === "Sell") {
      setSellerQuestion(true);
      /*//const chatNode = document.getElementsByClassName('buy-chat')[0].getElementsByClassName('rcw-launcher')[0];
            //if (chatNode ==== 'undefined') return;
            //chatNode.click(); // triggers click event on chat*/
    }
  };

  const openInfoModal = (e) => {
    setBotspressShow(!botspressShow)
  }

  const closeInfoModal = (value) => {
    setInfoModal(false);
  };

  const RefferalConfirm = (props) => {
    // console.log("rendering many times");
    return (
      <>
        <div id="popup1" className="overlay_2">
          <div className="popup1">
            <div className="popup1-header">
              <img src={LOGO} alt="Logo" effect="blur" className="logoImg" />
            </div>
            <a className="close">
              <img
                src={CLOSEICON}
                onClick={() => handleChange(false)}
                alt="loading..."
              />
            </a>
            <section className="multi_step_form">
              <form id="msform">
                <fieldset>
                  <div className="conten" style={{ minHeight: 272 }}>
                    <p className="good_day_para">
                      Please confirm which action you want to perform ?
                    </p>

                    {/* <button className="customBluebut btn btn-primary"
                                            onClick={() => {
                                                props.user_type === 'seller' ? setListingOption(false) : setReferralOption(false);
                                                dispatch(listingActions.reset());
                                            }}>{props.user_type === "seller" ? 'Upload Own Listing' : 'Upload Own Request'}</button>

                                        <button className="customBluebut btn btn-primary" onClick={() => {
                                            props.user_type === 'seller' ? setListingOption(true) : setReferralOption(true);
                                            dispatch(referralListingActions.reset());
                                        }}>{props.user_type === "seller" ? 'Upload Refferal Listing' : 'Upload Refferal Request'}</button> */}

                    <div className="button-wrap">
                      <input
                        className="hidden radio-label"
                        type="radio"
                        name="SellType"
                        value="Lease"
                        id="yes-button"
                        onClick={() => {
                          props.user_type === "seller"
                            ? setListingOption(false)
                            : setReferralOption(false);
                          dispatch(listingActions.reset());
                        }}
                      />
                      .
                      <label className="button-label" htmlFor="yes-button">
                        <h1>
                          {props.user_type === "seller"
                            ? "Upload Own Listing"
                            : "Upload Own Request"}
                        </h1>
                      </label>
                      <input
                        className="hidden radio-label"
                        type="radio"
                        name="SellType"
                        value="Sell"
                        id="no-button"
                        onClick={() => {
                          props.user_type === "seller"
                            ? setListingOption(true)
                            : setReferralOption(true);
                          dispatch(referralListingActions.reset());
                        }}
                      />
                      <label className="button-label" htmlFor="no-button">
                        <h1>
                          {props.user_type === "seller"
                            ? "Upload Refferal Listing"
                            : "Upload Refferal Request"}
                        </h1>
                      </label>
                    </div>
                  </div>
                </fieldset>
              </form>
            </section>
          </div>
        </div>
      </>
    );
  };

  const [packageStatus, setPackageStatus] = useState();
  const [nextBillingDate, setBillingDate] = useState();
  const [trialEndDate, setTrialEndDate] = useState();
  const [timeLeft, setTimeLeft] = useState({});
  useEffect(() => {
    // console.log("useeffect1");
    // "Function for get user  and get buyer package it will triggered ehren usercomes first time on page or refresh the page"
    getUserPackage() // Api function for get user package via api services
      .then((response) => {
        if (response?.data?.status === true) {
          // let x = new Date(response.data.data.subscription_end);
          // let billingDay = `${x.toLocaleString('default', { month: 'short' })} ${x.getUTCMonth()}, ${x.getFullYear()}`;
          // console.log('billingDay===', billingDay)
          setTrialEndDate(response?.data?.data?.trial_end);
          setPackageStatus(response?.data?.data?.free_package);
          calculateTimeLeft(response?.data?.data?.trial_end);
        }
      });
    // eslint-disable-next-line
  }, []);

  const calculateTimeLeft = (subscriptionEndTime) => {
    const difference = parseFloat(+new Date(subscriptionEndTime) - +new Date());
    let timeLeft_obj = {};
    if (difference > 0) {
      timeLeft_obj = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };

      // console.log("timeLeft_obj", timeLeft_obj);
      setTimeLeft(timeLeft_obj);
    }

    // return timeLeft_obj;

    //setTimeLeft(timeLeft_obj);
  };

  useEffect(() => {
    if (timeLeft != null) {
      setTimeout(() => {
        calculateTimeLeft(trialEndDate);
      }, 1000);
    }

    // eslint-disable-next-line
  }, [timeLeft]);

  return (
    <>
      <header className="page-header" id="page-header">
        <marquee width="100%" direction="left" height="20px" className="ct_Scroll_text_mob mt-2">
        For a better user experience and to access more features, please use a desktop.</marquee>

        <div className="container-fluid">
        <div className="d-flex pt-2 justify-content-center">
                  {Object.keys(timeLeft).length !== 0 ? (
                    <>
                      <p className="free_membership_ends_in_text">
                        Free Trial Ends On:
                      </p>

                      <div className="free_membership_time">
                        <>
                          {`${timeLeft?.hours}:${timeLeft?.minutes}:${timeLeft?.seconds}`}
                        </>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
          <div className="ct_mobile_place_change">
            <nav style={{paddingTop:"10px"}}>
              <div className="nav-left">
                <a>
                  <img
                    src={NOVILOGO}
                    alt="forecastr logo"
                    className="signup_logo"
                    onClick={resetDefaultFilter}
                  />
                </a>
                {botspressShow &&  (<BotpresssContainer isShow={botspressShow} userData={userDetails.user}/>)}

                <a>
                  <button className="sell_btn" id="Sell" onClick={buyLaunchChat}>
                    <img src={SELL} className="sell_icon" alt="sell" />
                    Sell
                  </button>
                </a>
                <a href="javascript:void(0)">
                  <button className="buy_btn" id="Buy" onClick={launchChat}>
                    <img src={BUY} className="sell_icon" alt="sell" />
                    Buy
                  </button>
                </a>
              </div>
              <div className="d-flex">
                
                <Link className="user-menu-link"
       to={"/job"}
                                      >
                <button type="button" className="help_btn  ct_map_icon_12 w-auto d-none d-md-block me-2"  onClick={job} style={{ whiteSpace: "nowrap" }} >
                 Jobs
                </button>
                </Link>
                <button type="button" className="help_btn  ct_map_icon_12 w-auto d-none d-md-block" style={{ whiteSpace: "nowrap" }} onClick={() => openInfoModal('')}>
                  Information Research
                </button>

                <Link
                  to={"/suggestion"}
                  className="user-menu-link"
                >
                  <button type="button" className="help_btn mx-2 ct_suggestion_btn_1 ">
                    Suggestions
                  </button>
                </Link>
                <div id="google_translate_element" className="d-none d-xl-block d-md-block d-lg-block ct_btn_h_43"> </div>
                <button type="button" className="help_btn ms-2">
                  <img src={HELP} className="help_icon" alt="help" />
                  Help
                </button>
                <div id="google_translate_element" className="d-none d-xl-block d-md-block d-lg-block ms-2"> </div>
                <div className="user-menu-wrap">
                  <div
                    className="account-icon"
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                  >
                    <Avatar alt="Remy Sharp" src={AVATAR} />
                    <LazyLoadImage
                      src={AVATARDROP}
                      alt="AVATARDROP"
                      effect="blur"
                      className="ml-2 img-fluid"
                      style={{ width: "20px", height: "auto", cursor: "pointer" }}
                    />
                  </div>
                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                            >
                              <ul className="user-menu">
                                <li className="user-menu__item">
                                  <a
                                    className="user-menu-link"
                                    href="javascript:void(0)"
                                  >
                                    <div onClick={updateProfile}>
                                      Profile{" "}
                                      {userMembership ? (
                                        <>(Membership: {userMembership}) </>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </div>
                                  </a>
                                </li>
                                <li className="user-menu__item">
                                  <a
                                    className="user-menu-link"
                                    href="javascript:void(0)"
                                  >
                                    <div>Membership</div>
                                  </a>
                                </li>
                              </ul>

                              {userProfile ? (
                                <>
                                  <ul className="user-menu">
                                    <li className="user-menu__item">
                                      <Link
                                        to={"/profile"}
                                        className="user-menu-link"
                                      >
                                        <div onClick={updateProfile}>
                                          Profile{" "}
                                          {userMembership ? (
                                            <>(Membership: {userMembership}) </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </Link>
                                    </li>
                                    <li className="user-menu__item">
                                      <Link
                                        className="user-menu-link"
                                        to={"/membership"}
                                      >
                                        <div>Membership</div>
                                      </Link>
                                    </li>
                                    <li className="user-menu__item">
                                      <Link
                                        className="user-menu-link"
                                        to={"/billing-history"}
                                      >
                                        <div>Billing History</div>
                                      </Link>
                                    </li>
                                    {/* <li className="user-menu__item">
                                    <a
                                      className="user-menu-link"
                                      href="javascript:void(0)"
                                    >
                                      <div>My Account</div>
                                    </a>
                                  </li> */}
                                    <li className="user-menu__item">
                                      <Link
                                        className="user-menu-link"
                                        to={"/dashboard"}
                                      >
                                        <div>Items For Sale</div>
                                      </Link>
                                    </li>
                                    <li className="user-menu__item">
                                      <Link
                                        className="user-menu-link"
                                        to={"/sell-dashboard"}
                                      >
                                        <div>Items Wanted</div>
                                      </Link>
                                    </li>
                                    <li className="user-menu__item">
                                      <Link
                                        to={"/seller-listing"}
                                        className="user-menu-link"
                                        href="javascript:void(0)"
                                      >
                                        <div>Manage Listings</div>
                                      </Link>
                                    </li>
                                    <li className="user-menu__item">
                                      <Link
                                        to={"/favorites"}
                                        className="user-menu-link"
                                        href="javascript:void(0)"
                                      >
                                        <div>My Favorites</div>
                                      </Link>
                                    </li>
                                    <li className="user-menu__item">
                                      <Link
                                        to={"/your-requests"}
                                        className="user-menu-link"
                                        href="javascript:void(0)"
                                      >
                                        <div>Manage Requests</div>
                                      </Link>
                                    </li>
                                    {/* <li className="user-menu__item">
                                      <a
                                        className="user-menu-link"
                                        href="javascript:void(0)"
                                      >
                                        <div>Language</div>
                                      </a>
                                    </li> */}

                                    <li className="user-menu__item">
                                      <Link
                                        to={"/suggestion"}
                                        className="user-menu-link"
                                        href="javascript:void(0)"
                                      >
                                        <div>Suggestions</div>
                                      </Link>
                                    </li>
                                    <li className="user-menu__item">
                                      <Link
                                        to={"/my-job"}
                                        className="user-menu-link"
                                        href="javascript:void(0)"
                                      >
                                        <div>My Jobs</div>
                                      </Link>
                                    </li>
                                    <li className="user-menu__item">
                                      <a
                                        className="user-menu-link"
                                        href="javascript:void(0)"
                                      >
                                        <div
                                          onClick={logoutUser}
                                          style={{ color: "#F44336" }}
                                        >
                                          Logout
                                        </div>
                                      </a>
                                    </li>
                                  </ul>
                                </>
                              ) : (
                                <></>
                              )}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </div>
            </nav>
          </div>
                    {/* Pass the function to the mobile filter component */}

          <SearchFilterMobile dashboardFunc={dashboardFunc} openInfoModal={openInfoModal} />
        </div>
      </header>

      {sellerQuestion ? (
        userDetails.profile.profile_data.is_referral === 1 ? (
          <RefferalConfirm user_type="seller" />
        ) : (
          <SellerListing
            profileStatus={userProfile}
            sellerData={sellerListing}
            userData={userDetails}
            onChange={handleChange}
          />
        )
      ) : refferalListing ? (
        <RefferalAgentListing
          profileStatus={userProfile}
          sellerData={sellerListing}
          userData={userDetails}
          onChange={handleChange}
        />
      ) : sellerListing ? (
        <SellerListing
          profileStatus={userProfile}
          sellerData={sellerListing}
          userData={userDetails}
          onChange={handleChange}
        />
      ) : buyerQuestion ? (
        userDetails.profile.profile_data.is_referral === 1 ? (
          <RefferalConfirm user_type="buyer" />
        ) : (
          <BuyerListing
            profileStatus={userProfile}
            sellerData={sellerListing}
            userData={userDetails}
            onChange={handleChange}
          />
        )
      ) : refferalRequest ? (
        <RefferalAgentRequest
          profileStatus={userProfile}
          sellerData={sellerListing}
          userData={userDetails}
          onChange={handleChange}
        />
      ) : buyerRequest ? (
        <BuyerListing
          profileStatus={userProfile}
          sellerData={sellerListing}
          userData={userDetails}
          onChange={handleChange}
        />
      ) : (
        ""
      )}
      {/* Information Modal call */}
      <InformationModal
        infoModal={infoModal}
        setShowInfoModal={closeInfoModal}
      />
    </>
  );
};

export default AfterLoginHeader;
