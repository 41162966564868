import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  updateProfile,
  userLogin,
  verifyUser,
} from "../api_services/api_calls/authApi";

/******* Calling Dynamic URL ********/
// const apiUrl = process.env.REACT_APP_API_KEY;

export const loginUser = createAsyncThunk(
  // thunk for call login user token api
  "users/login",
  async ({ phone, verification_code }, thunkAPI) => {
    try {
      const response = await verifyUser({ phone, verification_code })
        .then((res) => {
          if (res?.data?.status === true) {
            localStorage.setItem("phone", phone);
            localStorage.setItem("token", res.data.data.token);
            localStorage.setItem("chat_uid", res.data.data.user_chat_id);
            // localStorage.setItem('has_membership',res.data.duser.ata.has_membership);
            return res.data;
          } else {
            return thunkAPI.rejectWithValue(res.data);
          }
        })
        .catch((err) => {
          thunkAPI.rejectWithValue(err);
        });

      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const fetchUserBytoken = createAsyncThunk(
  // thunk for call fetchUserByToken token api
  "users/fetchUserByToken",
  async ({ token }, thunkAPI) => {
    try {
      const response = await userLogin({ token })
        .then((res) => {
          if (res?.data?.status === true) {
            localStorage.setItem('has_membership',res.data.data.user.has_membership);
            return { ...res.data };
          } else {
            return thunkAPI.rejectWithValue(res.data);
          }
        })
        .catch((err) => {
          thunkAPI.rejectWithValue(err);
        });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateUserByToken = createAsyncThunk(
  // thunk for call update user by token api
  "users/updateUserByToken",
  async (
    {
      first_name,
      middle_name,
      last_name,
      company,
      phone2,
      address,
      yob,
      street_second,
      country_id,
      state_id,
      postal_code,
      lat_lng,
      additional_info,
    },
    thunkAPI
  ) => {
    try {
      const response = await updateProfile({
        first_name,
        middle_name,
        last_name,
        company,
        phone2,
        address,
        yob,
        street_second,
        country_id,
        state_id,
        postal_code,
        lat_lng,
        additional_info,
      })
        .then((res) => {
          if (res.data.status === true) {
            return { ...res.data };
          } else {
            return thunkAPI.rejectWithValue(res.data);
          }
        })
        .catch((err) => {
          thunkAPI.rejectWithValue(err);
        });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const initialState = {
  // Initial state for user data
  privateLoadingStatus: "loading",
  loggedInStatus: false,
  isFetching: false,
  isSuccess: false,
  isUpdate: false,
  isError: false,
  errorMessage: "",
  profileStatus: false,
  userData: null,
  isSeenSiteOverview: false,
  isReferral: null,
};

const userSlice = createSlice({
  // slice for user
  name: "user",
  initialState,
  reducers: {
    // reducer for update user data
    updateUserData: (state, { payload }) => {
      state.userData = payload;
    },
    updateBecomeReferralAgentStatus: (state, { payload }) => {
      //state.isReferral = 'pending';
      state.isReferral = 'pending';
    },
    logout: (state) => {
      localStorage.clear();
      state.privateLoadingStatus = "error";
      state.isSuccess = false;
      state.loggedInStatus = false;
    },
    stepToUserDetail: (state, { payload }) => {
      state.isSeenSiteOverview = payload;
    }
  },

  extraReducers: {
    [loginUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.privateLoadingStatus = "loaded";
      state.loggedInStatus = true;
      return state;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchUserBytoken.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchUserBytoken.fulfilled]: (state, { payload }) => {
      state.privateLoadingStatus = "loaded";
      state.isFetching = false;
      state.isSuccess = false;
      state.isUpdate = false;
      state.loggedInStatus = true;
      state.profileStatus =
        payload?.data?.profile.profile_status === true ? true : false;
      state.userData = payload;
      return state;
      //state.username = payload.name;
    },
    [fetchUserBytoken.rejected]: (state) => {
      state.privateLoadingStatus = "error";
      state.isFetching = false;
      state.loggedInStatus = false;
      return state;
    },
    [updateUserByToken.pending]: (state) => {
      state.isFetching = true;
    },
    [updateUserByToken.fulfilled]: (state, { payload }) => {
      state.userData = payload;
      state.isUpdate = true;
      return state;
      //state.username = payload.name;
    },
    [updateUserByToken.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
      state.isUpdate = false;
      return state;
    },
  },
});

export const { logout, updateBecomeReferralAgentStatus, stepToUserDetail } = userSlice.actions;
export const { updateUserData } = userSlice.actions;
export const userSelector = (state) => state.user;

export default userSlice.reducer;
