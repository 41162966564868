import { Url } from "../api_settings/api_constants";
import { wrappedFetch } from "../api_settings/urlFunctions";

export const sellAddItemSubmit = (data, id) => {
  return wrappedFetch(Url.SELL_ADD_ITEM_LISTING_STORE + id, "POST", data);
};
//sell button listing store
export const sellButtonSubmit = (data) => {
  return wrappedFetch(Url.SELL_BUUTTON_LISTING_STORE, "POST", data);
};

export const referralRequestSubmit = (data) => {
  return wrappedFetch(Url.BECOME_REFERRAL_REQUEST_STORE, "POST", data);
};

export const updateListingPrice =(data,id) => {
  return wrappedFetch(Url.SELL_BUUTTON_PACKAGE_LISTING_PRICE_STORE + id, "POST", data);
}
