import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  buyerRequests: [],
  isNewRequestCreated: false,  // Track if a new request was created
  loading: false,
  error: null,
};

const requestSlice = createSlice({
  name: 'buyerRequest',
  initialState,
  reducers: {
    setNewRequestCreated: (state, action) => {
      state.isNewRequestCreated = action.payload;
    },
  },
});

export const { setNewRequestCreated } = requestSlice.actions;

export default requestSlice.reducer;