import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { userSelector } from "../store/userSlice";
import { useHistory, useLocation } from "react-router-dom";

import UnSubscribeModal from "../components/Modals/unSubscribeModal";
import dateFormat from "dateformat";
// import { CIRCLECHECKMARK } from "../constants/imagepath";
import AfterLoginHeader from "../components/header/AfterLoginHeader";
import Footer from "../components/footer/Footer";
import {
  getContent
} from "../api_services/api_calls/contentPageApi";
import axios from "axios";
import { getJobs, postJob, searchJob, filterJobs, getJob, getUserJob } from "../api_services/api_calls/jobsApi";
import { Tooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from "react-loading-skeleton";
import { useForm } from "react-hook-form";
import {
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import { JobModal } from "./Modals/JobModal";
import InfiniteScroll from "react-infinite-scroll-component";

function useQuery() {
  // "help in parsing and accessing query parameters"
  return new URLSearchParams(useLocation().search);
}

export default function Job(props) {
  const { userData } = useSelector(userSelector);
  let query = useQuery(); // "We will use useQuery function here"
  let query_param_id = query.get("myjob"); // "Variable for query param for get id"
  const {
    register,
    trigger,
    setError,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm(); // using react useform hook values

  // const [packages, setPackages] = useState([]);
  const [postType, setPostType] = useState("Job Wanted");
  const [filterVal, setFilterVal] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [nextBillingDate, setBillingDate] = useState();
  const [currentPackagePrice, setCurrentPackagePrice] = useState([]);
  const [packageName, setPackageName] = useState([]);
  const [packageStatus, setPackageStatus] = useState();
  // const [packageActive, setPackageActive] = useState();
  const [isSubscribed, setIsSubscribed] = useState();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [searchData, setSearchData] = useState(false);
  const [itemsCount, setItemsCount] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const inputref = useRef(null);



  const toggle = (tooltipId) => {
    setTooltipOpen(tooltipOpen === tooltipId ? null : tooltipId);
  };
  console.log('tooltipOpen', tooltipOpen)
  /******* Calling Dynamic URL ********/
  // const apiUrl = process.env.REACT_APP_API_KEY;

  const handleToggle = (packageId) => {
    // handle to manage pop up for particular id
    setConfirmModal(true);
    setSelectedPlan(packageId);
  };
  // const [loader, setLoader] = useState(false);
  // Fetch data based on the slug, or use a static mapping
  const [pageTitle, setPageTitle] = useState("");
  const [pageContent, setPageContent] = useState("");
  const [jobsCount, setJobsCount] = useState(1);
  const [loader, setLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [jobDetail, setJobDetail] = useState([]);

  const apiUrl = process.env.REACT_APP_API_KEY;

  function onTabChange(value) {
    // handle for set tab of jobs
    console.log('val', value)
    setLoader(true);

    const selectedVal = value;
    if (selectedVal !== '') {
      setPostType(selectedVal);
    }
    filterJobCall(selectedVal, filterVal);
  }

  function handleChange(event) {
    // handle for set filter of jobs
    setLoader(true);
    const selectedVal = event.target.value;
    const selectedPostType = postType ? postType : 'Job Wanted';
    console.log('postType', postType)
    if (selectedVal !== '') {
      setFilterVal(selectedVal);
      setPostType(selectedPostType)
    }
    filterJobCall(selectedPostType, selectedVal);


  }


  const fetchData = async () => {
    console.log('fetchData', fetchData)
    // "Function for fetch data for search filters and this function will trigered when user scroll the product page"
    setPage(page + 1);
    var pageNew;
    pageNew = page + 1;
    console.log('page', pageNew)

    const obj = { job_offered_or_job_wanted: postType, marine_or_fishing: filterVal }
    if (!query_param_id) {
    await filterJobs(obj, pageNew).then(function (resp) {
      //setHasMoreItems(false);
      const jobsResponse = resp.data.jobslist.data;
      const respCount = Array.isArray(jobsResponse) ? jobsResponse.length : Object.keys(jobsResponse).length;
      setJobs([...jobs, ...Array.isArray(jobsResponse) ? jobsResponse : Object.values(jobsResponse)]);
      setJobsCount(respCount);
      var itemsCountNew = itemsCount + respCount;
      setItemsCount(itemsCountNew);
      if (respCount > 0) {
        setHasMoreItems(true);
        // setCardDataAppend(true);
      } else {
        setHasMoreItems(false);
        // setCardDataAppend(false);
        setPage(1);
      }
      setSearchData(false)
    }).catch(function (error) {
      setLoader(false);
    });
  }
  else{
    await getUserJob(obj, pageNew).then(function (resp) {
      //setHasMoreItems(false);
      const jobsResponse = resp.data.jobslist.data;
      const respCount = Array.isArray(jobsResponse) ? jobsResponse.length : Object.keys(jobsResponse).length;
      setJobs([...jobs, ...Array.isArray(jobsResponse) ? jobsResponse : Object.values(jobsResponse)]);
      setJobsCount(respCount);
      var itemsCountNew = itemsCount + respCount;
      setItemsCount(itemsCountNew);
      if (respCount > 0) {
        setHasMoreItems(true);
        // setCardDataAppend(true);
      } else {
        setHasMoreItems(false);
        // setCardDataAppend(false);
        setPage(1);
      }
      setSearchData(false)
    }).catch(function (error) {
      setLoader(false);
    });
  }

  };


  useEffect(() => { // function for get the jobs after loading or refreshing thepage

    console.log('useEffectCalling')
    console.log('query_params', query_param_id)
    setLoader(true);
    const obj = {};
    // if (filterType !== 'All') {
    //   obj.posting_type = filterType;
    // }
    if (!query_param_id) {
      filterJobs(obj, page) // api call for get the jobs via api services
        .then(function (response2) {
          setLoader(false);
          var jobsResponse = response2.data.jobslist.data;
          console.log('response1', jobsResponse);
          const jobsData = Array.isArray(jobsResponse) ? jobsResponse : Object.values(jobsResponse);
          setJobsCount(Array.isArray(jobsResponse) ? jobsResponse.length : Object.keys(jobsResponse).length);
          setJobs(jobsData);
          setItemsCount(Array.isArray(jobsResponse) ? jobsResponse.length : Object.keys(jobsResponse).length);
        })
        .catch(function (error) {
          setLoader(false);
        });
    } else {
      const obj = {};
      getUserJob(obj, page).then(function (response2) {
        setLoader(false);
        console.log('response2', response2)
        var jobsResponse = response2.data.jobslist;
        const jobsData = Array.isArray(jobsResponse) ? jobsResponse : Object.values(jobsResponse);
        setJobsCount(Array.isArray(jobsResponse) ? jobsResponse.length : Object.keys(jobsResponse).length);
        setJobs(jobsData);
        setItemsCount(Array.isArray(jobsResponse) ? jobsResponse.length : Object.keys(jobsResponse).length);
      })
        .catch(function (error) {
          setLoader(false);
        });
    }
    // eslint-disable-next-line
  }, [query_param_id]);

  const filterJobsByuser = (data) => {
    console.log('response_data', data)
    return data.filter(record => record.user_id === userData.user.id);

  }
  const filterJobCall = (job_type, filter_val) => {
    const obj = { job_offered_or_job_wanted: job_type, marine_or_fishing: filter_val }
    if(!query_param_id){
      filterJobs(obj, page).then(function (resp) {
        setLoader(false);
        const jobsResponse = resp.data.jobslist.data;
        setJobs(Array.isArray(jobsResponse) ? jobsResponse : Object.values(jobsResponse));
        setJobsCount(Array.isArray(jobsResponse) ? jobsResponse.length : Object.keys(jobsResponse).length);
        setSearchData(false)
        setSearchParam("");
        inputref.current.value = "";
      }).catch(function (error) {
        setLoader(false);
      });
  }
  else{
    getUserJob(obj, page).then(function (response2) {
      setLoader(false);
      console.log('response2', response2)
      var jobsResponse = response2.data.jobslist;
      const jobsData = Array.isArray(jobsResponse) ? jobsResponse : Object.values(jobsResponse);
      setJobsCount(Array.isArray(jobsResponse) ? jobsResponse.length : Object.keys(jobsResponse).length);
      setJobs(jobsData);
      setItemsCount(Array.isArray(jobsResponse) ? jobsResponse.length : Object.keys(jobsResponse).length);
    })
      .catch(function (error) {
        setLoader(false);
      });
  }

  }

  const calculateTimeDifference = (dateString: string) => {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Get the current date
    const now = new Date();

    // Calculate the difference in milliseconds
    const diffInMs = now - date;

    // Convert milliseconds to days
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    // Format the result
    return `${diffInDays}d ago`;
  };
  console.log('userdetails', userData)
  const onSubmit = async (data) => {
    // "this function is using for add the new job submit"
    console.log('submit form')
    console.log('jobTitle', data.jobTitle)
    setSubmitting(true)
    const user_name = userData.data.profile.profile_data?.first_name + ' ' + userData.data.profile.profile_data?.middle_name + ' ' + userData.data.profile.profile_data?.last_name;
    let obj = {
      user_id: userData.data.user.id,
      name: data.userName,
      email: data.userEmail,
      phone_no: data.userPhone,
      title: data.jobTitle,
      job_type: data.jobType,
      posting_type: data.postingType,
      preferred_location: data.jobLocation,
      description: data.jobDescription,
      work_location: data.workLocation,
      job_duration: data.jobDuration,
      salary: data.jobSalary,
      certifications: data.certifications,
      additional_information: data.additionalInformation,
    };
    postJob(obj) // function for update seller request listing api call via api services
      .then(function (response2) {
        setSubmitting(false)
        reset();
        toast.success(response2.data.message);
      })
      .catch(function () {
        toast.error("Something went wrong");
      });
      
  };

  // Handle search logic
  const handleSearch = async () => {
    setLoader(true);
    let searchObj = { job_title: searchParam }
    searchJob(searchObj) // function for update seller request listing api call via api services
      .then(function (response2) {
        setLoader(false);
        var jobsResponse = response2.data;
        setJobsCount(Object.keys(jobsResponse.jobslist).length);
        setJobs(jobsResponse.jobslist);
        setSearchData(true);
      })
      .catch(function () {
        toast.error("Something went wrong");
      });

  };

  // Handle Input Change
  const handleInputChange = (event) => {
    setSearchParam(event.target.value);
  };
  // Handle Enter key press
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission if inside a form
      handleSearch();
    }
  };

  const handleOpenModal = (id) => {
    console.log('selectedId', id)
    setJobDetail([]);
    const obj = { job_post_id: id, user_id: userData.data.user.id };
    getJob(obj)
      .then(function (response2) {
        setLoader(false)
        var listingResponse = response2.data;
        setJobDetail(listingResponse.jobDetails);

      })
      .catch(function (error) {
        console.log(error);
      });
    //setJobDetail(selectedJob)
    setIsModalOpen(true);
  };


  // Function to get job details by ID
  const getJobById = (id) => {
    return jobs.find(job => job.id === id);
  };

  const resetFilter = () => {
    setLoader(true);
    filterJobCall(postType, filterVal);
  }


  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  console.log('jobsList', jobs)
  console.log('itemsCount', itemsCount)

  return (
    <>

      <AfterLoginHeader userData={userData} />
      <section className="package_section ct_px_0 pt-5">
        <div className="container">
          <div className="row">

            <div className="ct_job_tab_btns">
              <button className={`save_changes_btn py-2 ${postType === "Job Wanted" ? "active" : ""}`} onClick={() => onTabChange('Job Wanted')}>Job Wanted</button>
              <button className={`save_changes_btn py-2 ${postType === "Job Offered" ? "active" : ""}`} onClick={() => onTabChange('Job Offered')}>Job Offered</button>

            </div>
            <div className="col-md-12 text-end mb-3">
              <div className="d-flex align-items-center gap-2 justify-content-between">
                <div className="ct_job_search">
                  <input type="text" placeholder="Search Job" className="form-control mb-0" onChange={handleInputChange}
                    ref={inputref} onKeyDown={handleKeyDown} />
                  {searchData ?
                    <><span onClick={resetFilter}><i className="fa fa-close" /></span></>
                    :
                    <><span><i className="fa fa-search" /></span>
                    </>
                  }
                </div>
                <div className="d-flex align-items-center gap-2">
                  <div>
                    <select name="" id="" onChange={handleChange} className="form-control ct_skill_select mb-0">
                      <option value="">All</option>
                      <option value="Marine">Marine</option>
                      <option value="Fishing">Fishing</option>
                    </select>
                  </div>
                  <div>
                    <button className="save_changes_btn mb-0 py-2" data-bs-toggle="modal" data-bs-target="#addnewjob">+ Add New Job</button>

                  </div>
                </div>

              </div>
            </div>

          </div>
          {
            loader ?
              <Skeleton count={7.7} />
              :
              <div className="infinite-scroll-section ct_infinite_scroll_hide_on_responsive">
                <InfiniteScroll
                  dataLength={itemsCount}
                  next={fetchData}
                  hasMore={hasMoreItems}
                  loader={
                    <div
                      style={{
                        position: "absolute",
                        left: "50%",
                        bottom: "0",
                        border: "8px solid #ced6d9",
                        borderRadius: "50%",
                        borderTop: "8px solid #004964",
                        width: "50px",
                        height: "50px",
                        webkitAnimation: "spin 2s linear infinite" /* Safari */,
                        animation: "spin 2s linear infinite",
                      }}
                    ></div>
                  }
                  height={500}
                  className={"row ct_dash_scroll_big_screen"}
                  scrollThreshold={0.95}
                  endMessage={
                    <p className="ct_pad_15" style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                >
                  {
                    jobs.map((job, index) => (
                      <div className="col-lg-6 mb-4" key={index}>
                        <div className="ct_job_card">
                          <h6>{job.posting_type}</h6>
                          <h4>{job.title}</h4>

                          <ul>
                            <li>
                              {/* {calculateTimeDifference(job.creation_time)} */}
                              {dateFormat(job.creation_time, "mmmm dS, yyyy")}
                            </li>
                            <li>
                              {job.job_type}
                            </li>
                            <li>{job.preferred_location}</li>
                          </ul>
                          <div className="d-flex mt-2" >
                          <button className="btn btn-sm btn-outline-primary" onClick={() => handleOpenModal(job.id)}>Read More</button>
                            <div className="text-end" style={{ width: "70%" }}>
                              {
                                job.job_status == 1 ? 
                                <span class="badge badge-success p-2">Open</span> : 
                                  <span class="badge badge-warning p-2">Filled</span>
                              }
                            </div>
                          </div>
                          

                        </div>

                      </div>
                    ))
                  }
                </InfiniteScroll>

              </div>
          }
        </div>
      </section>
      <div class="modal fade" id="addnewjob" tabindex="-1" aria-labelledby="addnewjobLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header  border-0">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} >
              <div class="modal-body">
                <div className="ct_job_form">
                  <h4 className="text-center">Add New Job</h4>
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <div className="form-group mb-3">
                        <label htmlFor="" className="ct_fw_600 ct_fs_16">Post Type</label>
                        <select
                          id="postingType"
                          className="form-control"
                          {...register('postingType', { required: 'This field is required' })}
                        >
                          <option value="">Select Posting Type</option>
                          <option value="Marine Job Offered">Marine Job Offered</option>
                          <option value="Marine Job Wanted">Marine Job Wanted</option>
                          <option value="Fishing Job Offered">Fishing Job Offered</option>
                          <option value="Fishing Job Wanted">Fishing Job Wanted</option>
                        </select>
                        {errors?.postingType && (
                          <span className="err-msg">{errors?.postingType?.message}</span>
                        )}
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="" className="ct_fw_600 ct_fs_16">Job Title or Desired Job Title</label>
                        <span
                          id="Tooltip1"
                          className="pl-3"
                          style={{ display: 'inline-block', cursor: 'pointer' }}
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </span>

                        <Tooltip
                          isOpen={tooltipOpen === 'Tooltip1'}
                          autohide={false}
                          placement="top"
                          target="Tooltip1"
                          toggle={() => toggle('Tooltip1')}
                          popperClassName="product-tooltip"
                        >
                          Enter the specific job title. If you are posting a job offer, use the official job title. If you are seeking a job, enter the title of the job you are looking for.                          <br />
                        </Tooltip>
                        <input type="text" className="form-control" placeholder="e.g., Fishing Captain, Deckhand, Marine Engineer" {...register('jobTitle', { required: 'This field is required' })} />
                        {errors?.jobTitle && (
                          <span className="err-msg">{errors?.jobTitle?.message}</span>
                        )}
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="" className="ct_fw_600 ct_fs_16">Job Type</label>
                        <span
                          id="Tooltip2"
                          className="pl-3"
                          style={{ display: 'inline-block', cursor: 'pointer' }}
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                        <Tooltip
                          isOpen={tooltipOpen === 'Tooltip2'}
                          autohide={false}
                          placement="top"
                          target="Tooltip2"
                          toggle={() => toggle('Tooltip2')}
                          popperClassName="product-tooltip"
                        >
                          Select the nature of the job. 'Seasonal (Fishing Season)' applies to jobs available only during specific fishing seasons, 'Trip-Based' refers to positions hired per fishing trip, and 'On-Call' is for jobs where workers are needed on short notice. If none of these apply, select 'Other' and specify.<br />
                        </Tooltip>
                        <select
                          id="jobType"
                          className="form-control"
                          {...register('jobType', { required: 'This field is required' })}
                        >
                          <option value="">Select Job Type</option>
                          <option value="Full-Time">Full-Time</option>
                          <option value="Part-Time">Part-Time</option>
                          <option value="Seasonal (Fishing Season)">Seasonal (Fishing Season)</option>
                          <option value="Trip-Based">Trip-Based</option>
                          <option value="Contract (Short-Term)">Contract (Short-Term)</option>
                          <option value="Contract (Long-Term)">Contract (Long-Term)</option>
                          <option value="On-Call">On-Call</option>
                          <option value="Other">Other</option>
                        </select>
                        {errors?.jobType && (
                          <span className="err-msg">{errors?.jobType?.message}</span>
                        )}
                      </div>

                      <div className="form-group mb-3">
                        <label htmlFor="" className="ct_fw_600 ct_fs_16">Location or Preferred Location</label>
                        <span
                          id="Tooltip3"
                          className="pl-3"
                          style={{ display: 'inline-block', cursor: 'pointer' }}
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                        <Tooltip
                          isOpen={tooltipOpen === 'Tooltip3'}
                          autohide={false}
                          placement="top"
                          target="Tooltip3"
                          toggle={() => toggle('Tooltip3')}
                          popperClassName="product-tooltip"
                        >
                          Specify the location of the job or your preferred work location. Include details like city, state, or offshore if relevant.<br />
                        </Tooltip>
                        <input type="text" className="form-control" placeholder="e.g., Halifax, NS, Offshore Newfoundland" {...register('jobLocation', { required: 'This field is required' })} />
                        {errors?.jobLocation && (
                          <span className="err-msg">{errors?.jobLocation?.message}</span>
                        )}
                      </div>

                      <div className="form-group mb-3">
                        <label htmlFor="" className="ct_fw_600 ct_fs_16">Job Duration</label>
                        <span
                          id="Tooltip4"
                          className="pl-3"
                          style={{ display: 'inline-block', cursor: 'pointer' }}
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                        <Tooltip
                          isOpen={tooltipOpen === 'Tooltip4'}
                          autohide={false}
                          placement="top"
                          target="Tooltip4"
                          toggle={() => toggle('Tooltip4')}
                          popperClassName="product-tooltip"
                        >
                          Select the expected duration of the job. If it's a specific short-term job, choose the appropriate duration. If the job length is variable or not listed, select 'Other' and specify.'
                          Validation: This is a required field.<br />
                        </Tooltip>
                        <select
                          id="jobDuration"
                          className="form-control"
                          {...register('jobDuration', { required: 'This field is required' })}
                        >
                          <option value="1 Day">1 Day</option>
                          <option value="2-3 Days">2-3 Days</option>
                          <option value="1 Week">1 Week</option>
                          <option value="2-3 Weeks">2-3 Weeks</option>
                          <option value="1 Month">1 Month</option>
                          <option value="Ongoing">Ongoing</option>
                          <option value="Other">Other</option>
                        </select>
                        {errors?.jobDuration && (
                          <span className="err-msg">{errors?.jobDuration?.message}</span>
                        )}
                      </div>

                      <div className="form-group mb-3">
                        <label htmlFor="" className="ct_fw_600 ct_fs_16">Job Description or Experience Summary</label>
                        <span
                          id="Tooltip5"
                          className="pl-3"
                          style={{ display: 'inline-block', cursor: 'pointer' }}
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                        <Tooltip
                          isOpen={tooltipOpen === 'Tooltip5'}
                          autohide={false}
                          placement="top"
                          target="Tooltip5"
                          toggle={() => toggle('Tooltip5')}
                          popperClassName="product-tooltip"
                        >
                          For job offers, describe the role, including duties, responsibilities, and qualifications needed. If you are seeking a job, summarize your relevant experience and skills.<br />
                        </Tooltip>
                        <textarea
                          id="jobDescription"
                          {...register('jobDescription', {
                            required: 'This is a required field',
                          })}
                          rows="4"
                          cols="30"
                          placeholder="e.g., 5+ years experience in commercial fishing, responsible for navigation and crew management..."
                        />
                        {errors?.jobDescription && (
                          <span className="err-msg">{errors?.jobDescription?.message}</span>
                        )}
                      </div>

                      <div className="form-group mb-3">
                        <label htmlFor="" className="ct_fw_600 ct_fs_16">Salary/Wages or Desired Salary/Wages</label>
                        <span
                          id="Tooltip6"
                          className="pl-3"
                          style={{ display: 'inline-block', cursor: 'pointer' }}
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                        <Tooltip
                          isOpen={tooltipOpen === 'Tooltip6'}
                          autohide={false}
                          placement="top"
                          target="Tooltip6"
                          toggle={() => toggle('Tooltip6')}
                          popperClassName="product-tooltip"
                        >
                          If you are offering a job, provide the salary or wage range. If you are looking for a job, enter your expected salary range.<br />
                        </Tooltip>
                        <input type="text" className="form-control" placeholder="e.g., $50,000 - $70,000 per year, $25 per hour, or based on catch share" {...register('jobSalary')} />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="" className="ct_fw_600 ct_fs_16">Certifications</label>
                        <span
                          id="Tooltip7"
                          className="pl-3"
                          style={{ display: 'inline-block', cursor: 'pointer' }}
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                        <Tooltip
                          isOpen={tooltipOpen === 'Tooltip7'}
                          autohide={false}
                          placement="top"
                          target="Tooltip7"
                          toggle={() => toggle('Tooltip7')}
                          popperClassName="product-tooltip"
                        >
                          List any certifications required for the job you are offering or certifications you hold if you are seeking a job. Separate multiple certifications with commas.<br />
                        </Tooltip>
                        <input type="text" className="form-control" placeholder="e.g., Marine First Aid, Fishing Master Class IV, STCW" {...register('certifications')} />
                      </div>

                      <div className="form-group mb-3">
                        <label htmlFor="" className="ct_fw_600 ct_fs_16">Berthage of Vessel or Work Location</label>
                        <span
                          id="Tooltip8"
                          className="pl-3"
                          style={{ display: 'inline-block', cursor: 'pointer' }}
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                        <Tooltip
                          isOpen={tooltipOpen === 'Tooltip8'}
                          autohide={false}
                          placement="top"
                          target="Tooltip8"
                          toggle={() => toggle('Tooltip8')}
                          popperClassName="product-tooltip"
                        >
                          Specify the exact berthage of the vessel or the specific work location. Include details like the name of the port, dock, or offshore location.<br />
                        </Tooltip>
                        <input type="text" className="form-control" placeholder="e.g., Pier 21, Halifax Harbour" {...register('workLocation', { required: 'This is a required field' })} />
                        {errors?.workLocation && (
                          <span className="err-msg">{errors?.workLocation?.message}</span>
                        )}
                      </div>

                      <div className="form-group mb-3">
                        <label htmlFor="" className="ct_fw_600 ct_fs_16">Additional Information</label>
                        <textarea
                          id="additionalInformation"
                          {...register('additionalInformation')}
                          rows="4"
                          cols="30"
                          placeholder="e.g., Flexible working hours, on-board accommodations available, opportunity for advancement..."
                        />
                      </div>
                      <div class="row form-group mb-3">
                      <label for="" class="col-sm-4 t_fw_600 ct_fs_16">Disclaimer </label>
                      <div class="col-sm-8">
                          <span
                          id="Tooltip9"
                          className="pl-3"
                          style={{ display: 'inline-block', cursor: 'pointer' }}
                          >
                          <FontAwesomeIcon icon={faInfoCircle} />
                          </span>

                          <Tooltip
                          isOpen={tooltipOpen === 'Tooltip9'}
                          autohide={false}
                          placement="top"
                          target="Tooltip9"
                          toggle={() => toggle('Tooltip9')}
                          popperClassName="product-tooltip"
                          >
                          By submitting this job, you consent to the display of your job details and contact information (phone number or email) to logged-in users. Novi Marine Brokers and its staff are not liable for any communications, transactions, or claims arising from this posting, and you agree to hold them harmless. You are responsible for vetting contacts and marking the job as filled. This agreement is governed by the laws of Canada, the USA, and other relevant jurisdictions.                         <br />
                          </Tooltip>
                        </div>
                        <div className="form-group mb-3">
                        <label htmlFor="" className="ct_fw_600 ct_fs_16">Name</label>
                        <input type="text" className="form-control" placeholder="Enter your name" {...register('username', { required: 'This field is required' })} />

                        {errors?.userName && (
                          <span className="err-msg">{errors?.userName?.message}</span>
                        )}
                      </div>
                        <div className="form-group mb-3">
                        <label htmlFor="" className="ct_fw_600 ct_fs_16">Email Id</label>
                        <input type="text" className="form-control" placeholder="Enter your email" {...register('userEmail', { required: 'This field is required', pattern: { value: /\S+@\S+\.\S+/, message: "Entered value does not match email format" }, })} />

                        {errors?.userEmail && (
                          <span className="err-msg">{errors?.userEmail?.message}</span>
                        )}
                      </div>
                      
                      <div className="form-group mb-3">
                        <label htmlFor="" className="ct_fw_600 ct_fs_16">Phone Number</label>
                        <input type="text" className="form-control" placeholder="Enter your phone number" {...register('userPhone', { required: 'This field is required', valueAsNumber: true })} />

                        {errors?.userPhone && (
                          <span className="err-msg">{errors?.userPhone?.message}</span>
                        )}
                      </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
              <div class="modal-footer border-0">
                <button type="subnit" disabled={submitting ? true : false} class="save_changes_btn">{submitting ? 'Submitting' : 'Save Changes'}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <JobModal isOpen={isModalOpen} onClose={() => handleCloseModal()} jobDetail={jobDetail} userData={userData?.data?.user} />
      <Footer />


    </>
  );
}
