import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const PackagePricingStep = ({ handleFormData, 
  values,
  setValue,
  register,
  error,
  clearErrors,
  control
}) => {
  return (
    <>
    <h5>Set Your Desired Net Price</h5>
      <p className="good_day_para mt-2">Please enter the amount you wish to receive from the sale. Our commission and any applicable fees will be added on top of this amount to form the advertised price.</p>
      <p>For example, if you wish to net a certain amount, the final advertised price will include our commission and applicable fees. These will be deducted from the final selling price, and the remainder will be your net proceeds.</p>
      <div className="row">
          <div className="col-md-12">
            <input
              type="text"
              name="PackagePricing"
              min="0"
              className="popup_input"
              id="PackagePricing"
              {...register("PackagePricing", {
                required: "This field is required",
              })}
              onChange={(e) => {
                clearErrors("PleasureBoatName");
                handleFormData(e);
              }}
            />
            
          </div>
          </div>
      
    </>
  );
}

export default PackagePricingStep;
