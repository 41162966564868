
import { Modal, ModalBody, ModalFooter } from "reactstrap";

import '../../assets/css/style.css'

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { unSubscribeUserPlan } from "../../api_services/api_calls/memberShipApis";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const UnSubscribeModal = ({ confirmModal, setConfirmModal, selectedPlan }) => {


    const handleChangeClick = () => {
        setConfirmModal(false)
    }

    const unSubscribeConfirm = () => {
        setConfirmModal(false)
    }




    const handleUnsubscribeUser = () => {
        // handleToggle()
        let planPackageId = {}
        planPackageId['subscriptionId'] = selectedPlan

        unSubscribeUserPlan(planPackageId)
            .then(res => {
                if (res) {
                    unSubscribeConfirm()
                    toast.success(res?.data?.message)

                }
            }).catch(function (error) {
                console.log(error);
            });
    }


    return (
        <>
            <Modal
                className="modal-dialog-centered confirm-modal"
                isOpen={confirmModal}
            >
                <button type="button" onClick={handleChangeClick} className="closeBtn soldprice"><span aria-hidden="true" className="crossbtn">×</span></button>
                <ModalBody>
                    <div className="MainContainer">
                        <div className="row">
                            <div className="col-lg-12 col-sm-12">
                                <h5>Are you sure you want to Unsubscribe this plan?</h5>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="row">
                        <div className="col-lg-4">
                            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={handleUnsubscribeUser} >Yes</button>
                        </div>&nbsp;&nbsp;
                        <div className="col-lg-4">
                            <button type="button" className="btn btn-primary" onClick={handleChangeClick} data-dismiss="modal">No</button>
                        </div>
                    </div>

                </ModalFooter>
            </Modal>
        </>




    );
};




export default UnSubscribeModal;