import React, { useEffect } from 'react'

const BotPressChatbot = ({show}) => {
  useEffect(() => {

      if(show)
      {
        const script = document.createElement('script')
    script.src = 'https://cdn.botpress.cloud/webchat/v1/inject.js'
    script.async = true
    document.body.appendChild(script)

    script.onload = () => {
      window.botpressWebChat.init({
        botId: '2d9d16ac-235f-406d-a15f-ffbab5eb1bab',
        hostUrl: 'https://cdn.botpress.cloud/webchat/v1',
        messagingUrl: 'https://messaging.botpress.cloud',
        clientId: '2d9d16ac-235f-406d-a15f-ffbab5eb1bab',
      });
    };

    document.body.appendChild(script);

        // Cleanup function to remove the script when component unmounts
        return () => {
            document.body.removeChild(script);
        };
      }
    
  }, [show])

  return <div id="webchat" />
}

export default BotPressChatbot