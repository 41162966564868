import { useState, useEffect, lazy } from "react";
import { Link } from "react-router-dom";
import "./listing.css";
import { LOGO, PdfImage, CLOSEICON } from "../../constants/imagepath";
import { useForm } from "react-hook-form";
import heic2any from "heic2any";
// sell listing Api function import from api services
import {
  sellButtonSubmit,
  referralRequestSubmit,
  sellAddItemSubmit,
  updateListingPrice
} from "../../api_services/api_calls/sellButtonApi";
import {
  getCommercialVehicleTypes,
  getEquipmentOptions,
  getLicenseOptions,
  getPleasureBoatOptions,
  getSeaFoodOptions,
  objectFilterNew,
} from "../../api_services/api_calls/listingObjectApi";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../store/userSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateBecomeReferralAgentStatus } from "../../store/userSlice";

import SellerListingCompleted from "./SellerListingCompleted";
import Step1 from "./SellerListingSteps/Step1";
import Step2 from "./SellerListingSteps/Step2";
import Step3 from "./SellerListingSteps/Step3";
import Step4 from "./SellerListingSteps/Step4";
import Step5 from "./SellerListingSteps/Step5";
import Step6 from "./SellerListingSteps/Step6";
import Step7 from "./SellerListingSteps/Step7";
import Step8 from "./SellerListingSteps/Step8";
import Step9 from "./SellerListingSteps/Step9";
import Step10 from "./SellerListingSteps/Step10";
import Step11 from "./SellerListingSteps/Step11";
import BecomeReferralAgentModal from "../Modals/BecomeReferralAgentModal";
import BecomeReferralAgentRequestCompleted from "./BecomeReferralAgentRequestCompleted";
import PackagePricingStep from "./SellerListingSteps/packagePricingStep";
import { listingActions } from '../../store/listingSlice';


const SellerListing = (props) => {
  // console.log('this si seller listing')
  const {
    register,
    trigger,
    setError,
    formState: { errors },
    clearErrors,
    reset,
    setValue,
    control,
    getValues,
  } = useForm(); // using react useform hook values
  const [step, setStep] = useState(0);
  const [stepActive, setActiveStep] = useState(2);
  const [userName, setUserName] = useState();
  const [listingCompleted, setListingCompleted] = useState(false);
  const [referralAgentRequestModalOpen, setReferraAgentModalOpen] =
    useState(false);
  const [referralAgentRequestComplete, setReferraAgentRequestComplete] =
    useState(false);
  const dispatch = useDispatch(); // "Function for dispatch an action"
  const { isReferral } = useSelector(userSelector);
  const [state, setState] = useState({
    //object inside state for store and update the value of inputs fields of seller
    SellType: "Lease",
    ItemType: "Boat",
    PackageItemType: "",
    VesselType: "",
    VesselTypeOther: "",
    OfficialNumber: "",
    VesselName: "",
    VesselRegNum: "",
    VesselLocation: "",
    VesselLengthFoot: "",
    VesselLengthInch: "",
    VesselLengthUnit: "FT-IN",
    VesselExtensionFlag: false,
    VesselExtensionFoot: "",
    VesselExtensionInch: "",
    VesselExtensionUnit: "FT-IN",
    VesselWidthFoot: "",
    VesselWidthInch: "",
    VesselWidthUnit: "FT-IN",
    TypeOfEngine: "",
    EngineBrandName: "",
    EngineModelNumber: "",
    EngineHorsePower: "",
    EngineHours: "",
    TypeOfReduction: "",
    ReductionRatio: "",
    TypeOfShaftMetarial: "",
    SizeOfShaft: "",
    SizeOfShaftUnit: "",
    EngineRebuilt: "",
    PresentCondition: "Excellent",
    BuyerReachTime: "Morning",
    ItemPrice: "",
    ItemPriceUnit: "",
    UploadLater: false,
    ImageData: "",
    SubscriptionPackage: "",
    AgreementSign: false,
    ConstructionMet: "",
    ConstructionMetNew: "",
    ConstructionTyp: "",
    AgreementType: "",
    PackagePricing: ""
  });

  const [listingOptions, setListingOptions] = useState({
    //object inside state for store and update the value of inputs of seller (broker category)
    constructionTypes: [],
    constructions: [],
    vesselTypes: [],
    berths: [],
    enginetypes: [],
    enginemanufacturers: [],
    enginevolts: [],
    marineGearManufacturer: [],
    generalSpecies: [],
    brokerRegion: [],
    brokerArea: [],
    brokerLicenseTypeGeneral: [],
    brokerQuotaSaleType: [{ id: 4, name: 'Permanent' }, { id: 5, name: 'Temporary' }],
    states: [],
    brokerPleasureClass: [],
    brokerPleasureCategory: [],
    brokerEquipmentCategory: [],
    brokerEquipmentProduct: [],
    brokerGeneralSpecies: [],
    brokerGeneralSpeciesSize: [],
  });

  // states for store and update the values of selller inputs,errors etc.
  const [selectedImage, setSelectedImage] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [submittingPrice, setSubmittingPrice] = useState(false);

  const [boatAddress, setBoatAddress] = useState("");
  const [boatProvince, setBoatProvince] = useState("");
  const [boatCountry, setBoatCountry] = useState("");
  const [boatPostalCode, setBoatPostalCode] = useState("");
  const [boatLatLng, setBoatLatLng] = useState("");
  const [btnError, setBtnError] = useState("");
  const [imgloading, setImgLoading] = useState(false);
  const [flag, setFlag] = useState(true);
  const [loader, setLoader] = useState(false);
  const [currentListingId, setCurrentListingId] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [addMoreItems, setAddMoreItems] = useState(false);
  const [addPackagePricing, setAddPackagePricing] = useState(false);
  // console.log("errorMsg===", errorMsg);

  // Set the pdf url for different services
  var fsboPdf = "http://www.africau.edu/images/default/sample.pdf";
  var fsbbPdf = "https://www.clickdimensions.com/links/TestPDFfile.pdf";

  function imageDisplay(e) {
    // var imageData1 = Object.values(e.target.files);
    if (e.target.files && e.target.files.length > 0) {
      setErrorMsg(false);
      // setRespMsg("")
      setImgLoading(true);
      // setUploading(true)
      var files = e.target.files;
      let image_Arr = [];
      const allowedFileTypes = [
        "jpg",
        "jpeg",
        "gif",
        "png",
        "heic",
        "mp4",
        "mpeg",
        "ogg",
        "webm",
      ];
      Array.from(files).forEach((file) => {
        var fileNameExt = file.name.substr(file.name.lastIndexOf(".") + 1);
        if (!allowedFileTypes.includes(fileNameExt)) {
          setErrorMsg(
            `File does not support. Files type must be ${allowedFileTypes.join(
              "/ "
            )}`
          );
          setImgLoading(false);
          return false;
        } else {
          if (fileNameExt == "heic") {
            var blob = file;
            heic2any({
              blob: blob,
              toType: "image/jpg",
            })
              .then(function (resultBlob) {
                image_Arr.push(resultBlob);
              })
              .catch(function (x) {
                console.log(x.code);
                console.log(x.message);
              });
          } else {
            image_Arr.push(file);
          }
        }
      });
      setTimeout(() => {
        setImgLoading(false);
        // setUploading(false);
        setSelectedImage([...selectedImage, ...image_Arr]);
      }, 3000);
    }
  }

  // This function will be triggered when the "Remove This Image" button is clicked
  const removeSelectedImage = (e) => {
    var array = [...selectedImage]; // make a separate copy of the array
    var index = e.target.name;
    if (index !== -1) {
      array.splice(index, 1);
      setSelectedImage(array);
    }
  };

  useEffect(() => {
    console.log('userDaata', props.userData)
    // use effect for call the get apis via api services object filter new, license, pleasure boat ,equipment seafood
    const referralRequestStatus = props.userData.profile.profile_data.referral_status ? props.userData.profile.profile_data.referral_status : isReferral;
    console.log('referralRequestStatus', referralRequestStatus)
    if (props.profileStatus) {
      setUserName(props.userData.profile.profile_data.first_name);
    } else {
      setUserName(props.userData.user.email);
    }
    getCommercialVehicleTypes().then(function (response) {
      setListingOptions((listingOptions) => ({
        ...listingOptions,
        vesselType: response.data.jobslist
      }));
    })
      .catch(function (error) {
        console.log(error);
      });
    objectFilterNew() // object filter new api call function import via api services
      .then(function (response) {
        setListingOptions((listingOptions) => ({
          ...listingOptions,
          berths: response.data.data.berths,
          constructionTypes: response.data.data.constructiontypes,
          constructions: response.data.data.constructions,
          enginemanufacturers: response.data.data.enginemanufacturers,
          enginetypes: response.data.data.enginetypes,
          enginevolts: response.data.data.enginevolts,
          marineGearManufacturer: response.data.data.marinegearmanufacturers,
          states: response.data.data.states,
        }));
      })
      .catch(function (error) {
        console.log(error);
      });

    getLicenseOptions() //license object api call function import via api services
      .then(function (response1) {
        if (response1.data.status) {
          setListingOptions((listingOptions) => ({
            ...listingOptions,
            generalSpecies: response1.data.data.generalSpecies,
            brokerRegion: response1.data.data.region,
            brokerArea: response1.data.data.brokerArea,
            brokerLicenseTypeGeneral:
              response1.data.data.brokerLicenseTypeGeneral,
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    getPleasureBoatOptions() //pleasure boat options api call function import via api services
      .then(function (response1) {
        if (response1.data.status) {
          setListingOptions((listingOptions) => ({
            ...listingOptions,
            brokerPleasureCategory: response1.data.data.brokerPleasureCategory,
            brokerPleasureClass: response1.data.data.brokerPleasureClass,
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    getEquipmentOptions() //equipment options api call function import via api services
      .then(function (response1) {
        if (response1.data.status) {
          setListingOptions((listingOptions) => ({
            ...listingOptions,
            brokerEquipmentCategory: response1.data.data.brokerGearCategory,
            brokerEquipmentProduct: response1.data.data.brokerGearProduct,
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    getSeaFoodOptions() //seaFood options api call function import via api services
      .then(function (response1) {
        if (response1.data.status) {
          setListingOptions((listingOptions) => ({
            ...listingOptions,
            brokerGeneralSpecies: response1.data.data.brokerGeneralSpecies,
            brokerGeneralSpeciesSize:
              response1.data.data.brokerGeneralSpeciesSize,
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  function handleChange(evt) {
    // this function is triggered when user changes the values of input field
    const value = evt.target.value;
    if (typeof evt.target.value == "string" && value.trim().length === 0) {
      setError(evt.target.name, {
        type: "required",
        message: "This field is required.",
      });
    } else {
      setState({
        ...state,
        [evt.target.name]: value,
      });
    }
  }

  function handleChangeOther(evt) {
    // this function is triggered when user changes the values of input field
    const value = evt.target.value;
    if (typeof evt.target.value == "string" && value.trim().length === 0) {
      setError(evt.target.name, {
        type: "required",
        message: "This field is required.",
      });
    } else {
      setStateOther({
        ...stateOther,
        [evt.target.name]: value,
      });
    }
  }
  function handleChangeBoat(evt) {
    // this function is triggered when user changes the values of input field
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }
  function handleChangeOtherNormal(evt) {
    // this function is triggered when user changes the values of input field
    const value = evt.target.value;
    setStateOther({
      ...stateOther,
      [evt.target.name]: value,
    });
    //focusOnElement(evt.target.id);
  }

  function handleCustomButtonSelect(evt) {
    // this function is triggered when user changes the values of input field
    const value = evt.target.value;
    console.log('selectedsValue', value)
    console.log('step_val', step);
    setState({
      ...state,
      [evt.target.name]: value,
    });
    //setStep(step - 1 );
    //reset();
    //focusOnElement(evt.target.id);
  }

  function focusOnElement(id) {
    setTimeout(() => {
      document.getElementById(id).focus();
    }, 5);
  }

  function handleAddress(data) {
    setBoatAddress(data.address);
    setBoatProvince(data.state_id);
    setBoatCountry(data.country_id);
    setBoatPostalCode(data.postal_code);
    setBoatLatLng(data.lat_lng);
  }

  // handle used for set values of Address like provience, country,postal code ,lat_lng etc.
  function triggerValidation(action) {
    console.log('action_s', action);
    console.log('step_tigger', step)
    //alert(step)
    const fields_arr = [
      {},
      {},
      {
        fields: {
          Boat: ["VesselName"],
          License: [
            "LicenseNumber",
            "LicenseRegion",
            "LicenseSpecies",
            "LicenseSubSpecies",
            "LicenseArea",
            "LicenseProvince",
            "LicenseType",
            "ItemPrice",
          ],
          Quota: [
            "LicenseNumber",
            "LicenseRegion",
            "LicenseSpecies",
            "LicenseSubSpecies",
            "LicenseArea",
            "LicenseProvince",
            "LicenseType",
            "SaleType",
            "ItemPrice",
          ],
          PleasureBoat: [
            "PleasureBoatName",
            "PleasureClass",
            "PleasureCategory",
            "ItemPrice",
          ],
          Equipment: [
            "EquipmentModel",
            "EquipmentSerialNumber",
            "EquipmentManufacturer",
            "EquipmentCategory",
            "EquipmentProduct",
            "EquipmentYear",
            "ItemPrice",
          ],
          Seafood: [
            "SeaFoodSpecies",
            "SeaFoodSize",
            "SeaFoodQuantity",
            "ItemPrice",
          ],
        },
      },
      {
        fields: ["VesselLocation"],
      },
      {
        fields: ["ConstructionMet"],
      },
      {
        fields: [
          "VesselLengthFoot",
          "VesselLengthInch",
          "VesselExtensionFoot",
          "VesselExtensionInch",
          "VesselWidthFoot",
          "VesselWidthInch",
        ],
      },
      {
        fields: [
          "TypeOfEngine",
          "EngineBrandName",
          "EngineHorsePower",
          "EngineHours",
        ],
      },
      {
        fields: ["TypeOfReduction"],
      },
      {
        fields: ["ItemPrice"],
      },
      {},
      {
        fields: ["AgreementType"],
      },
    ];
    if (step === 0) {
      if (state.SellType === "") {
        setBtnError("Please select one of above");
        return false;
      } else {
        setBtnError("");
        setStep(step + 1);
        setActiveStep(stepActive + 1);
      }
    }
    if (step === 1) {
      if (state.ItemType === "") {
        setBtnError("Please select one of above");
        return false;
      } else {
        setBtnError("");
        setStep(step + 1);
        setActiveStep(stepActive + 1);
        if (state.ItemType !== "Boat") {
          setActiveStep(stepActive + 9);
        }
      }
    }
    if (step === 2) {
      //alert('trigger')
      if (state.PackageItemType === "") {
        setBtnError("Please select one of above");
        // return false;
      }
      console.log('currentitemType', state.ItemType)
      if (state.ItemType === "Boat" || state.PackageItemType === "Boat") {
        //return false;
        trigger(fields_arr[step].fields["Boat"]).then((valid) => {
          if (valid) {
            setStep(step + 1);
            setActiveStep(stepActive + 1);
          }
        });
      }
      if (state.ItemType === "License" || state.PackageItemType === "License") {
        trigger(fields_arr[step].fields["License"]).then((valid) => {
          if (valid)
            if (action === "next") {
              setStep(step + 1);
              setActiveStep(stepActive + 1);
            } else {
              submitListing();
              setFlag(false);
            }
        });
      }
      if (state.ItemType === "Quota" || state.PackageItemType === "Quota") {
        trigger(fields_arr[step].fields["Quota"]).then((valid) => {
          if (valid)
            if (action === "next") {
              setStep(step + 1);
              setActiveStep(stepActive + 1);
            } else {
              submitListing();
              setFlag(false);
            }
        });
      }
      if (state.ItemType === "Pleasure Boat" || state.PackageItemType === "Pleasure Boat") {
        trigger(fields_arr[step].fields["PleasureBoat"]).then((valid) => {
          if (valid)
            if (action === "next") {
              setStep(step + 1);
              setActiveStep(stepActive + 1);
            } else {
              submitListing();
              setFlag(false);
            }
        });
      }
      if (state.ItemType === "Equipment" || state.PackageItemType === "Equipment") {
        trigger(fields_arr[step].fields["Equipment"]).then((valid) => {
          if (valid)
            if (action === "next") {
              setStep(step + 1);
              setActiveStep(stepActive + 1);
            } else {
              submitListing();
              setFlag(false);
            }
        });
      }
      if (state.ItemType === "Seafood" || state.PackageItemType === "Seafood") {
        trigger(fields_arr[step].fields["Seafood"]).then((valid) => {
          if (valid)
            if (action === "next") {
              setStep(step + 1);
              setActiveStep(stepActive + 1);
            } else {
              submitListing();
              setFlag(false);
            }
        });
      }
    }
    if (step === 3) {
      if (state.ItemType === "License" || state.PackageItemType === "License") {
        trigger(fields_arr[step].fields["License"]).then((valid) => {
          if (valid) submitListing();
        });
      } else if (state.ItemType === "Quota" || state.PackageItemType === "Quota") {
        trigger(fields_arr[step].fields["Quota"]).then((valid) => {
          if (valid) submitListing();
        });
      } else {
        setStep(step + 1);
        setActiveStep(stepActive + 1);
      }
    }
    if (step === 4) {
      trigger(fields_arr[step].fields).then((valid) => {
        if (valid) setStep(step + 1);
        setActiveStep(stepActive + 1);
      });
    }
    if (step === 5) {
      trigger(fields_arr[step].fields).then((valid) => {
        if (valid) setStep(step + 1);
        setActiveStep(stepActive + 1);
      });
    }
    if (step === 6) {
      trigger(fields_arr[step].fields).then((valid) => {
        if (valid) setStep(step + 1);
        setActiveStep(stepActive + 1);
      });
    }
    if (step === 7) {
      trigger(fields_arr[step].fields).then((valid) => {
        if (valid) setStep(step + 1);
        setActiveStep(stepActive + 1);
      });
    }
    if (step === 8) {
      trigger(fields_arr[step].fields).then((valid) => {
        if (valid) setStep(step + 1);
        setActiveStep(stepActive + 1);
      });
    }
    if (step === 9 && state.PackageItemType !== 'Boat') {
      setStep(step + 1);
      setActiveStep(stepActive + 1);
      return true;
    }

    if (action === "final") {
      // console.log('final_step');
      if (state.AgreementType === "" && state.PackageItemType !== 'Boat') {
        setBtnError("Please select one of above");
        return false;
      } else {
        setBtnError("");
        submitListing();
        setFlag(false);
        return true;
      }
    }
  }

  // this handle will triggered on click of next button
  function setNextStep(event) {
    triggerValidation("next");
  }

  // this handle will triggered on click of back button
  function setPrevStep() {
    console.log('current_steps', step)
    if (state.PackageItemType && step === 2) {
      setState({
        ...state,
        ['PackageItemType']: "",
      });
    } else {
      setStep(step - 1);
    }
    setBtnError("");
    clearErrors();
  }

  function handleChangeClick(event) {
    props.onChange(false);
  }

  // handle for manage values of the a input button function
  function handleButtonSelect(evt) {
    console.log('event', evt.target);
    evt.className += "active";
    const value = evt.target.value;
    const name = evt.target.name;

    if (name === "become_referral_agent") {
      setReferraAgentModalOpen(true);
    } else {
      setState({
        ...state,
        [evt.target.name]: value,
      });
      setStep(step + 1);
      if (step === 1) {
        if (value === "License" || value === "Quota") {
          setActiveStep(stepActive + 9);
        } else if (
          value === "Pleasure Boat" ||
          value === "Equipment" ||
          value === "Seafood"
        ) {
          setActiveStep(stepActive + 10);
        }
      }
      setBtnError("");
      reset();
    }
  }
  // handle for manage values of the input field on Change function
  function handleButtonInput(evt) {
    //evt.className += "active";
    //evt.preventDefault();
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
    if (evt.target.name == "UploadLater") {
      setStep(step + 1);
      setActiveStep(stepActive + 1);
    }
  }

  // this function will triggered final validation on click of finish button
  function submitSellerListing() {
    triggerValidation("final");
  }

  const handleReferralConfirm = () => {
    referralRequestSubmit()
      //   api function for listing?store to stor eour recuired
      .then(function (response) {
        // setLoader(false);
        // setFlag(true);
        if (response.data.status) {
          setReferraAgentModalOpen(false);
          setReferraAgentRequestComplete(true);
          dispatch(updateBecomeReferralAgentStatus({ user_chat_id: 'dd' }));
        } else {
          alert("Some error occured.");
          setReferraAgentModalOpen(true);
        }
        // handleChangeClick();
      })
      .catch(function (error) {
        setSubmitting(false);
      });
  };

  // this handle will set the data in states and updated with latest value
  function submitListing() {
    setSubmitting(true);
    console.log('final_step', step);
    var data = new FormData();
    data.append("listing_type", state.ItemType);
    data.append("department", "2");
    if (state.SellType === "Sell") {
      data.append("product_sale_type", "1");
    } else {
      data.append("product_sale_type", "2");
    }
    data.append("agent", "");
    data.append("potential_agent", "");
    data.append("old_listing_number", "");
    data.append("is_active", "potential");
    data.append("status", "Potential");
    data.append("agreement_type", "exclusive");
    data.append("listing_date_year", new Date().getFullYear().toString());
    data.append(
      "listing_date_month",
      appendZero((new Date().getMonth() + 1).toString())
    );
    data.append(
      "listing_date_day",
      appendZero(new Date().getDate().toString())
    );
    data.append(
      "listing_date_hour",
      appendZero(new Date().getHours().toString())
    );
    data.append(
      "listing_date_minute",
      appendZero(new Date().getMinutes().toString())
    );
    data.append("listing_date_ampm", getAmPm(new Date().getHours().toString()));
    if (state.ItemType === "Boat") {
      data.append("package_type", "6");
      data.append("vessel_type", state.VesselType !== 'other' ? state.VesselType : '');
      data.append("vessel_type_other", (state.VesselType > 0 && state.VesselType !== 'other') ? '' : state.VesselTypeOther);
      data.append("boat_number", state.OfficialNumber);
      data.append("name", state.VesselName);
      data.append("berthed_at", state.VesselLocation);
      data.append("boat_location_address", boatAddress);
      data.append("boat_lat_lng", boatLatLng);
      data.append("boat_location_province", boatProvince);
      data.append("boat_location_country", boatCountry);
      data.append("boat_location_postal", state.boatPostalCode);
      data.append("length_foot", state.VesselLengthFoot);
      data.append("length_inch", state.VesselLengthInch);
      data.append("length_unit", state.VesselLengthUnit);
      data.append("extension_length_foot", state.VesselExtensionFoot);
      data.append("extension_length_inch", state.VesselExtensionInch);
      data.append("extension_length_unit", state.VesselExtensionUnit);
      data.append("width_foot", state.VesselWidthFoot);
      data.append("width_inch", state.VesselWidthInch);
      data.append("width_unit", state.VesselWidthUnit);
      data.append("engine_type", state.TypeOfEngine);
      data.append("engine_manufacturer", state.EngineBrandName);
      data.append("horse_power", state.EngineHorsePower);
      data.append("engine_hours", state.EngineHours);
      data.append("shaft_diameter", state.SizeOfShaft);
      //data.append('construction', state.ConstructionMet);
      data.append("construction", state.ConstructionMet);
      data.append("marine_gear_manufacturer", state.TypeOfReduction);
      data.append("marine_gear_ratio", state.ReductionRatio);
      data.append("engine_model_number", state.EngineModelNumber);
      data.append("engine_rebuilt", state.EngineRebuilt);
      data.append("shaft_metarial", state.TypeOfShaftMetarial);
      data.append("registration_number", state.VesselRegNum);
      data.append("boat_condition", state.PresentCondition);
      data.append("best_time_to_buy", state.BuyerReachTime);
      data.append("seller_listing_type", state.AgreementType);
      data.append("package_price", state.ItemPrice);
      data.append("upload_later", state.UploadLater);
      for (let i = 0; i < selectedImage.length; i++) {
        data.append("files[]", selectedImage[i]);
      }
    } else if (state.ItemType === "License") {
      data.append("package_type", "7");
      data.append("license_number", stateOther.LicenseNumber); // License Number
      data.append("license_currency", "USD");
      data.append("general_species", stateOther.LicenseSpecies);
      data.append("sub_species", stateOther.LicenseSubSpecies);
      data.append("region", stateOther.LicenseRegion);
      data.append("area", stateOther.LicenseArea);
      data.append("province", stateOther.LicenseProvince);
      data.append("license_type", stateOther.LicenseType);
      data.append("sale_type_details", stateOther.LicenseSaleType);
      data.append("package_price", stateOther.ItemPrice);
    } else if (state.ItemType === "Package") {
      data.append("package_type", "1");
      data.append('object_type', state.PackageItemType);
      if (state.PackageItemType === "Boat") {
        data.append("boat_number", state.OfficialNumber);
        data.append("vessel_type", state.VesselType !== 'other' ? state.VesselType : '');
        data.append("vessel_type_other", (state.VesselType > 0 && state.VesselType !== 'other') ? '' : state.VesselTypeOther);
        data.append("name", state.VesselName);
        data.append("berthed_at", state.VesselLocation);
        data.append("boat_location_address", boatAddress);
        data.append("boat_lat_lng", boatLatLng);
        data.append("boat_location_province", boatProvince);
        data.append("boat_location_country", boatCountry);
        data.append("boat_location_postal", state.boatPostalCode);
        data.append("length_foot", state.VesselLengthFoot);
        data.append("length_inch", state.VesselLengthInch);
        data.append("length_unit", state.VesselLengthUnit);
        data.append("extension_length_foot", state.VesselExtensionFoot);
        data.append("extension_length_inch", state.VesselExtensionInch);
        data.append("extension_length_unit", state.VesselExtensionUnit);
        data.append("width_foot", state.VesselWidthFoot);
        data.append("width_inch", state.VesselWidthInch);
        data.append("width_unit", state.VesselWidthUnit);
        data.append("engine_type", state.TypeOfEngine);
        data.append("engine_manufacturer", state.EngineBrandName);
        data.append("horse_power", state.EngineHorsePower);
        data.append("engine_hours", state.EngineHours);
        data.append("shaft_diameter", state.SizeOfShaft);
        //data.append('construction', state.ConstructionMet);
        data.append("construction", state.ConstructionMet);
        data.append("marine_gear_manufacturer", state.TypeOfReduction);
        data.append("marine_gear_ratio", state.ReductionRatio);
        data.append("engine_model_number", state.EngineModelNumber);
        data.append("engine_rebuilt", state.EngineRebuilt);
        data.append("shaft_metarial", state.TypeOfShaftMetarial);
        data.append("registration_number", state.VesselRegNum);
        data.append("boat_condition", state.PresentCondition);
        data.append("best_time_to_buy", state.BuyerReachTime);
        data.append("seller_listing_type", state.AgreementType);
        data.append("package_price", state.ItemPrice ?? 0.00);
        data.append("upload_later", state.UploadLater);
        for (let i = 0; i < selectedImage.length; i++) {
          data.append("files[]", selectedImage[i]);
        }
      } else if (state.PackageItemType === "Pleasure Boat") {
        data.append("name", stateOther.PleasureBoatName.replace(/\s/g, ""));
        data.append("pleasure_currency", "USD");
        data.append("pleasure_boat_category", stateOther.PleasureCategory);
        data.append("pleasure_boat_class", stateOther.PleasureClass !== 'other' ? stateOther.PleasureClass : '');
        data.append("pleasure_boat_class_other", (stateOther.PleasureClass > 0 && stateOther.PleasureClass !== 'other') ? '' : stateOther.PleasureClassOther);
        data.append("package_price", stateOther.ItemPrice ?? 0.00);
      } else if (state.PackageItemType === "License" || state.PackageItemType === "Quota") {
        data.append("license_number", stateOther.LicenseNumber); // License Number
        data.append("license_currency", "USD");
        data.append("general_species", stateOther.LicenseSpecies);
        data.append("sub_species", stateOther.LicenseSubSpecies);
        data.append("region", stateOther.LicenseRegion);
        data.append("area", stateOther.LicenseArea);
        data.append("province", stateOther.LicenseProvince);
        data.append("license_type", stateOther.LicenseType);
        data.append("sale_type_details", stateOther.LicenseSaleType);
        data.append("package_price", stateOther.ItemPrice ?? 0.00);
      } else if (state.PackageItemType === "Equipment") {
        data.append("serial_number", stateOther.EquipmentSerialNumber);
        data.append("gear_currency", "USD");
        data.append("gear_category", stateOther.EquipmentCategory);
        data.append("gear_Product_type", stateOther.EquipmentProduct);
        data.append("model_number", stateOther.EquipmentModel);
        data.append("gear_year", stateOther.EquipmentYear);
        data.append("package_price", stateOther.ItemPrice ?? 0.00);
        data.append("manufacturer_new", stateOther.EquipmentManufacturer);
      } else {
        data.append("seafood_currency", "USD");
        data.append("general_species", stateOther.SeaFoodSpecies);
        data.append("seafood_size_id", stateOther.SeaFoodSize);
        data.append("quantity", stateOther.SeaFoodQuantity);
        data.append("seafood_basic_desc", stateOther.SeaFoodDescription);
        data.append("package_price", stateOther.ItemPrice ?? 0.00);
      }
    } else if (state.ItemType === "Quota") {
      data.append("package_type", "21");
      data.append("license_number", stateOther.LicenseNumber); // License Number
      data.append("license_currency", "USD");
      data.append("general_species", stateOther.LicenseSpecies);
      data.append("sub_species", stateOther.LicenseSubSpecies);
      data.append("region", stateOther.LicenseRegion);
      data.append("area", stateOther.LicenseArea);
      data.append("province", stateOther.LicenseProvince);
      data.append("license_type", stateOther.LicenseType);
      data.append("sale_type", stateOther.SaleType);
      data.append("sale_type_details", stateOther.QuotaSaleType);
      data.append("package_price", stateOther.ItemPrice);
    } else if (state.ItemType === "Pleasure Boat") {
      data.append("package_type", "8");
      data.append("name", stateOther.PleasureBoatName.replace(/\s/g, ""));
      data.append("pleasure_currency", "USD");
      data.append("pleasure_boat_category", stateOther.PleasureCategory);
      data.append("pleasure_boat_class", stateOther.PleasureClass !== 'other' ? stateOther.PleasureClass : '');
      data.append("pleasure_boat_class_other", (stateOther.PleasureClass > 0 && stateOther.PleasureClass !== 'other') ? '' : stateOther.PleasureClassOther);
      data.append("package_price", stateOther.ItemPrice);
    } else if (state.ItemType === "Equipment") {
      data.append("package_type", "11");
      data.append("serial_number", stateOther.EquipmentSerialNumber);
      data.append("gear_currency", "USD");
      data.append("gear_category", stateOther.EquipmentCategory);
      data.append("gear_Product_type", stateOther.EquipmentProduct);
      data.append("manufacturer_new", stateOther.EquipmentManufacturer);
      data.append("model_number", stateOther.EquipmentModel);
      data.append("gear_year", stateOther.EquipmentYear);
      data.append("package_price", stateOther.ItemPrice);
    } else {
      data.append("package_type", "30");
      data.append("seafood_currency", "USD");
      data.append("general_species", stateOther.SeaFoodSpecies);
      data.append("seafood_size_id", stateOther.SeaFoodSize);
      data.append("quantity", stateOther.SeaFoodQuantity);
      data.append("seafood_basic_desc", stateOther.SeaFoodDescription);
      data.append("package_price", stateOther.ItemPrice);
    }

    function appendZero(val) {
      if (val < 10) {
        return "0" + val;
      }
      return val;
    }
    // handle for fet AM PMX
    function getAmPm(val) {
      if (val > 12) {
        return "pm";
      } else {
        return "am";
      }
    }



    setLoader(true);
    if (addMoreItems) {
      console.log('addMoreItems', addMoreItems);
      sellAddItemSubmit(data, currentListingId).then(function (response) {
        setLoader(false);

        setFlag(true);
        if (response.data.status) {
          setListingCompleted(true);
          reset();
        } else {
          alert("Some error occured.");
          setSubmitting(false);
        }
        // handleChangeClick();
      })
        .catch(function (error) {
          // setFlag(true);
          setSubmitting(false);
        });
    } else {
      sellButtonSubmit(data)
        //   api function for listing?store to stor eour recuired
        .then(function (response) {
          setLoader(false);

          setFlag(true);
          if (response.data.status) {
            console.log('resp_data', response.data.data)
            console.log('resp_package_data', response.data.data.package_type)

            if (response?.data?.data.package_type === 1) {
              console.log('listing_id', response?.data?.data.id)
              setCurrentListingId(response?.data?.data.id);
            }
            dispatch(listingActions.setNewListingCreated(true));  // Set state to indicate new request was created
            setListingCompleted(true);
            reset();
          } else {
            alert("Some error occured.");
            setSubmitting(false);
          }
          // handleChangeClick();
        })
        .catch(function (error) {
          // setFlag(true);
          setSubmitting(false);
        });
    }

  }



  const [stateOther, setStateOther] = useState({
    LicenseNumber: "",
    LicenseRegion: "",
    LicenseSpecies: "",
    LicenseSubSpecies: "",
    LicenseArea: "",
    LicenseProvince: "",
    LicenseType: "",
    LicensePrice: "",
    ItemPrice: "",
    LicenseSaleType: 9,
    QuotaSaleType: 8,
    SaleType: "",
    PleasureBoatName: "",
    PleasureClass: "",
    PleasureClassOther: "",
    PleasureCategory: "",
    PleasureBoatPrice: "",
    EquipmentCategory: "",
    EquipmentProduct: "",
    EquipmentPrice: "",
    EquipmentCurrency: "",
    EquipmentManufacturer: "",
    EquipmentModel: "",
    EquipmentYear: "",
    EquipmentSerialNumber: "",
    SeaFoodSpecies: "",
    SeaFoodSize: "",
    SeaFoodQuantity: "",
    SeaFoodPrice: "",
    SeaFoodCurrency: "",
    SeaFoodDescription: ""
  });

  // this function will trigger triggered Validation and
  function submitSellerListingOther() {
    triggerValidation("final");
  }

  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const handleReferralModal = (res) => setReferraAgentModalOpen(res);

  console.log('current_step', step)
  console.log('current_packageitem_type', state.PackageItemType)

  const handleMoreItems = () => {
    console.log('currentListingId', currentListingId)
    setListingCompleted(false)
    setSubmitting(false)
    setState({
      ...state,
      ['PackageItemType']: "",
    })
    setStep(2);
    setAddMoreItems(true);
    console.log('addMoreItemsAfter', addMoreItems)
  }

  const handlePackagePricing = () => {
    setListingCompleted(false)
    setAddPackagePricing(true);
  }

  const submitPackagePricing = () => {
    console.log('currentpackageListingId', currentListingId);
    console.log('packagePrice', state.PackagePricing)
    //return false;
    setSubmittingPrice(true)
    let obj = { listing_price: state.PackagePricing };
    updateListingPrice(obj, currentListingId) // api function for update the edited values of seller request
      .then((res) => {
        setSubmittingPrice(false)
        setAddPackagePricing(false);
        handleChangeClick();
        reset();
        toast.success(res.data.message);
        // setLoader(false);
      })
      .catch(function (error) {
        setSubmittingPrice(false)
      });
  }
  console.log('addPackagePrice', addPackagePricing)
  return (
    <>
      <div id="popup1" className="overlay_2">
        <div className="popup1">
          <div className="popup1-header">
            <LazyLoadImage
              src={LOGO}
              alt="Logo"
              effect="blur"
              className="logoImg"
            />
          </div>
          <a
            className="close"
            onClick={handleChangeClick}
            href="javascript:void(0)"
          >
            <img src={CLOSEICON} />
          </a>
          <section className="multi_step_form">
            <form id="msform">
              {(() => {
                return (
                  <ul id="progressbar">
                    {numbers.map((number) => (
                      <li className={stepActive >= number ? "active" : ""}></li>
                    ))}
                  </ul>
                );
              })()}
              <fieldset>
                <div className="conten" >
                  {listingCompleted ? (
                    <SellerListingCompleted
                      stateData={state}
                      stateOtherData={stateOther}
                      listingOptions={listingOptions}
                    />
                  ) : referralAgentRequestComplete ?
                    <BecomeReferralAgentRequestCompleted />
                    : referralAgentRequestModalOpen ?
                      <BecomeReferralAgentModal
                        isOpen={referralAgentRequestModalOpen}
                        setModalOpen={handleReferralModal}
                        onConfirm={handleReferralConfirm}
                      /> : addPackagePricing ?
                        <PackagePricingStep
                          values={state}
                          handleFormData={handleChange}
                          register={register}
                          clearErrors={clearErrors}
                          error={errors}
                          control={control}
                          setValue={setValue}
                        />
                        : step === 0 ? (
                          <Step1
                            userName={userName}
                            userData={props.userData.profile.profile_data}
                            referralStatus={
                              props.userData.profile.profile_data.referral_status ? props.userData.profile.profile_data.referral_status : isReferral}
                            values={state}
                            handleFormData={handleButtonSelect}
                            error={btnError}
                          />
                        ) : step === 1 ? (
                          <Step2
                            values={state}
                            handleFormData={handleButtonSelect}
                            error={btnError}
                          />
                        ) : step === 2 ? (
                          <Step3
                            values={state}
                            handleChangeOtherNormal={handleChangeOtherNormal}
                            handleCustomButtonSelect={handleCustomButtonSelect}
                            valuesOther={stateOther}
                            handleChangeNormal={handleChangeBoat}
                            handleFormData={handleChange}
                            handleFormDataOther={handleChangeOther}
                            selectValues={listingOptions}
                            register={register}
                            clearErrors={clearErrors}
                            error={errors}
                            btnError={btnError}
                            control={control}
                            setValue={setValue}
                            getValues={getValues}
                          />
                        ) : (state.ItemType === "Boat" || state.PackageItemType === "Boat" ||
                          state.ItemType === "License" || state.PackageItemType === "License" || state.ItemType === "Quota" || state.PackageItemType === "Quota") &&
                          step === 3 ? (
                          <Step4
                            values={state}
                            handleChangeNormal={handleChangeBoat}
                            handleChangeOtherNormal={handleChangeOtherNormal}
                            valuesOther={stateOther}
                            handleFormData={handleChange}
                            handleFormDataOther={handleChangeOther}
                            clearErrors={clearErrors}
                            selectValues={listingOptions}
                            register={register}
                            control={control}
                            setValue={setValue}
                            getValues={getValues}
                            error={errors}
                            handleAddress={handleAddress}
                          />
                        ) : (state.ItemType === "Boat" || state.PackageItemType === "Boat") && step === 4 ? (
                          <Step5
                            values={state}
                            handleFormData={handleChange}
                            handleChangeNormal={handleChangeBoat}
                            selectValues={listingOptions}
                            register={register}
                            clearErrors={clearErrors}
                            error={errors}
                            control={control}
                            setValue={setValue}
                            getValues={getValues}
                          />
                        ) : (state.ItemType === "Boat" || state.PackageItemType === "Boat") && step === 5 ? (
                          <Step6
                            values={state}
                            handleFormData={handleChange}
                            handleChangeNormal={handleChangeBoat}
                            selectValues={listingOptions}
                            register={register}
                            error={errors}
                            clearErrors={clearErrors}
                            control={control}
                            setValue={setValue}
                          />
                        ) : (state.ItemType === "Boat" || state.PackageItemType === "Boat") && step === 6 ? (
                          <Step7
                            values={state}
                            handleFormData={handleChange}
                            handleChangeNormal={handleChangeBoat}
                            selectValues={listingOptions}
                            register={register}
                            clearErrors={clearErrors}
                            error={errors}
                            control={control}
                            setValue={setValue}
                            getValues={getValues}
                          />
                        ) : (state.ItemType === "Boat" || state.PackageItemType === "Boat") && step === 7 ? (
                          <Step8
                            values={state}
                            handleFormData={handleChange}
                            handleChangeNormal={handleChangeBoat}
                            handleButtonInput={handleButtonInput}
                            selectValues={listingOptions}
                            register={register}
                            clearErrors={clearErrors}
                            error={errors}
                            control={control}
                            setValue={setValue}
                            getValues={getValues}
                          />
                        ) : (state.ItemType === "Boat" || state.PackageItemType === "Boat") && step === 8 ? (
                          <Step9
                            values={state}
                            handleFormData={handleChange}
                            handleChangeNormal={handleChangeBoat}
                            handleButtonInput={handleButtonInput}
                            selectValues={listingOptions}
                            styles={styles}
                            register={register}
                            clearErrors={clearErrors}
                            error={errors}
                            control={control}
                            setValue={setValue}
                          />
                        ) : (state.ItemType === "Boat" || state.PackageItemType === "Boat") && step === 9 ? (
                          <Step10
                            values={state}
                            handleImageDisplay={imageDisplay}
                            errorMsg={errorMsg}
                            handleChangeNormal={handleChangeBoat}
                            handleButtonInput={handleButtonInput}
                            selectedImage={selectedImage}
                            styles={styles}
                            removeSelectedImage={removeSelectedImage}
                            clearErrors={clearErrors}
                            imgloader={imgloading}
                          />
                        ) : (step === 10 && state.PackageItemType !== "Boat") ? (
                          <Step11
                            values={state}
                            handleButtonInput={handleButtonInput}
                            handleChangeNormal={handleChangeBoat}
                            metaData={[fsboPdf, fsbbPdf, PdfImage]}
                            styles={styles}
                            clearErrors={clearErrors}
                            error={btnError}
                          />
                        ) : (
                          ""
                        )}
                </div>

                {listingCompleted ? (
                  <center>
                    {state.ItemType === "Package" ?
                      <>
                        {/* <Link to="/seller-listing"> */}
                        <button
                          type="button"
                          className="action-button finish-btn float-none"
                          onClick={handlePackagePricing}
                        >
                          Proceed To Pricing
                        </button>
                        {/* </Link> */}
                        <button
                          type="button"
                          className="action-button float-none"
                          onClick={handleMoreItems}
                        >
                          Add More Items
                        </button>
                      </>
                      : <Link to="/seller-listing">
                        <button
                          type="button"
                          className="action-button float-none"
                          onClick={() => {
                            props.onChange(false);
                            // Trigger the refresh of the buyer request list by setting a state in BuyerRequest page
                          }}
                        >
                          Go To Sell Listing
                        </button>
                      </Link>}
                  </center>
                ) : referralAgentRequestModalOpen ? (
                  <></>
                ) : (
                  <>
                    <div className="d-flex justify-content-end">
                      {(step > 0 && !addPackagePricing) ? (
                        <button
                          type="button"
                          className="action-button previous previous_button"
                          disabled={submitting ? true : false}
                          onClick={setPrevStep}
                        >
                          Back
                        </button>
                      ) : (
                        ""
                      )}
                      {(state.ItemType !== "Boat" && step === 2 && state.ItemType !== "License" && state.ItemType !== "Package" && state.ItemType !== "Quota") ||
                        ((state.PackageItemType === "Pleasure Boat" || state.PackageItemType === "Equipment" || state.PackageItemType === "Seafood") && step === 2) ||
                        ((state.ItemType === "License" || state.PackageItemType === "License") && step === 3) ||
                        ((state.ItemType === "Quota" || state.PackageItemType === "Quota") && step === 3) ||
                        (state.PackageItemType === "Boat" && step === 9) ? ( // New condition added here
                        ""
                      ) : (!referralAgentRequestComplete && step < 10) ? (
                        <button
                          type="button"
                          className="next action-button"
                          disabled={submitting || errors.length > 0}
                          onClick={setNextStep}
                        >
                          Next
                        </button>
                      ) : (
                        ""
                      )}

                      {loader ? (
                        <div className="loader"></div>
                      ) :
                        (!addPackagePricing) ?

                          (state.ItemType === "Boat" && step === 10) || (state.PackageItemType === "Boat" && step === 9) ? (
                            <button
                              type="button"
                              className="next action-button finish_btn"
                              disabled={submitting ? true : false}
                              onClick={submitSellerListing}
                            >
                              {state.ItemType === "Package" ? "Submit" : "Finish"}
                            </button>
                          ) : ((state.ItemType === "Pleasure Boat" || state.PackageItemType === "Pleasure Boat" ||
                            state.ItemType === "Seafood" || state.PackageItemType === "Seafood" ||
                            state.ItemType === "Equipment" || state.PackageItemType === "Equipment"
                          ) &&
                            step === 2) ||
                            ((state.ItemType === "License" || state.PackageItemType === "License") && step === 3) || ((state.ItemType === "Quota" || state.PackageItemType === "Quota") && step === 3) ? (
                            <button
                              type="button"
                              className="next action-button finish_btn"
                              disabled={submitting ? true : false}
                              onClick={submitSellerListingOther}
                            >
                              {state.ItemType === "Package" ? "Submit" : "Finish"}
                            </button>
                          ) : (
                            ""
                          )
                          :
                          <button type="button"
                            className="next action-button finish_btn"
                            disabled={submittingPrice ? true : false}
                            onClick={submitPackagePricing}
                          >Submit Pricing</button>
                      }
                    </div>
                  </>
                )}
              </fieldset>
            </form>
          </section>
        </div>
      </div>


    </>
  );
};

export default SellerListing;

// Just some styles
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 50,
  },
  preview: {
    marginTop: 50,
    display: "flex",
    flexDirection: "column",
  },
  image: { maxWidth: "100%", maxHeight: 320 },
  delete: {
    cursor: "pointer",
    background: "red",
    color: "white",
    border: "none",
    borderRadius: "50%",
    width: "27px",
    position: "absolute",
    right: "18px",
  },
  displayImage: {
    border: "1px solid grey",
    margin: "5px 2px 2px 2px",
  },
  imgcontainer: {
    borderTop: "2px solid grey",
  },
};
