import React from "react";


const Step9 = ({ handleFormData, values, handleButtonInput, handleChangeNormal, register, error, clearErrors }) => {
    return (
        <>
            <p className="good_day_para mt-2 mb-2">Present Condition Of The Boat:</p>
            <div className="button-wrap">
                <input className="hidden radio-label" type="radio" name="PresentCondition" value="Excellent" id="excellent-button" checked={values.PresentCondition === 'Excellent' ? 'checked' : ''} onClick={handleButtonInput} />
                <label className="button-label_2" for="excellent-button">
                    <h1>Excellent</h1>
                </label>
                <input className="hidden radio-label" type="radio" name="PresentCondition" value="Good" id="good-button" checked={values.PresentCondition === 'Good' ? 'checked' : ''} onClick={handleButtonInput} />
                <label className="button-label_2" for="good-button">
                    <h1>Good</h1>
                </label>
                <input className="hidden radio-label" type="radio" name="PresentCondition" value="Average" id="average-button" checked={values.PresentCondition === 'Average' ? 'checked' : ''} onClick={handleButtonInput} />
                <label className="button-label_2" for="average-button">
                    <h1>Average</h1>
                </label>
                <input className="hidden radio-label" type="radio" name="PresentCondition" value="Poor" id="poor-button" checked={values.PresentCondition === 'Poor' ? 'checked' : ''} onClick={handleButtonInput} />
                <label className="button-label_2" for="poor-button">
                    <h1>Poor</h1>
                </label>
            </div>
            <p className="good_day_para mt-2 mb-2">What Is The Best Time For The Buyer To Reach You:</p>
            <div className="button-wrap">
                <input className="hidden radio-label" type="radio" name="BuyerReachTime" value="Morning" id="morning-button" checked={values.BuyerReachTime === 'Morning' ? 'checked' : ''} onClick={handleButtonInput} />
                <label className="button-label button-label_4" for="morning-button">
                    <h1>Morning</h1>
                </label>
                <input className="hidden radio-label" type="radio" name="BuyerReachTime" value="Mid-day" id="mid-day-button" checked={values.BuyerReachTime === 'Mid-day' ? 'checked' : ''} onClick={handleButtonInput} />
                <label className="button-label button-label_4" for="mid-day-button">
                    <h1>Mid - Day</h1>
                </label>
                <input className="hidden radio-label" type="radio" name="BuyerReachTime" value="Evening" id="evening-button" checked={values.BuyerReachTime === 'Evening' ? 'checked' : ''} onClick={handleButtonInput} />
                <label className="button-label button-label_4" for="evening-button">
                    <h1>Evening</h1>
                </label>
            </div>
            <input className="text-box_2" type="text" name="BuyerReachTime" value={values?.BuyerReachTime} placeholder="Other" onChange={handleChangeNormal} />
            {
                values?.ItemType!=="Package" ?
                <>
            <span className="popup_input_label">How Much Do Your Need In Your Pocket To Sell Your Vessel:<span className="required-field"></span></span>
            <input className="text-box_2" type="text" name="ItemPrice" defaultValue={values.ItemPrice} placeholder="From $ 1000-"   {...register("ItemPrice",
                {
                    required: "This field is required.",
                    validate: {
                        maxNumber: (value) => {
                            if (value < 100) {
                                return "Price should not be less than $100";
                            }
                        }
                    },
                }

            )}
                onChange={(e) => {
                    clearErrors("ItemPrice");
                    handleFormData(e);
                }} />

            {error?.ItemPrice && <span className="err-msg">{error?.ItemPrice?.message}</span>}
            </>: '' }
        </>
    );
}

export default Step9;
