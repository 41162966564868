/* eslint-disable jsx-a11y/anchor-is-valid */
// import { Avatar } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import { LOGO2, AVATAR, HELP, DROPDOWN } from "../../constants/imagepath";

// import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
// import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { logout } from "../../store/userSlice";
import { LOGO } from "../../constants/imagepath";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "../../components/Listing/listing.css";
import { listingActions } from "../../store/listingSlice";
import { referralListingActions } from "../../store/referralListingSlice";
import { getUserPackage } from "../../api_services/api_calls/memberShipApis";

const AddUserDetails = ({ headerCallback, userData }) => {
  // states for manage component data
  // const { currentUser } = useContext(CurrentUserContext);
  // const [showLoginModal, setShowLoginModal] = useState(false);
  // const [paymentModal, setPaymentModal] = useState(false);
  // const [threeDigitCode, setThreeDigitCode] = useState("");
  // const [cardHolderName, setCardHolderName] = useState("");
  // const [code, setCode] = useState("");
  const history = useHistory();
  const [userProfile, setUserProfile] = useState(false);
  const [userMembership, setUserMembership] = useState("");
  const [sellerListing, setSellerListing] = useState(false);
  const [refferalListing, setRefferalListing] = useState(false);
  const [sellerQuestion, setSellerQuestion] = useState(false);
  const [buyerQuestion, setBuyerQuestion] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [refferalRequest, setRefferalRequest] = useState(false);
  const [buyerRequest, setBuyerRequest] = useState(false);
  const [userDropdown, setUserDropdown] = useState(false);

  const dispatch = useDispatch(); // function for dispatch The action

  // const useStyles = makeStyles((theme) => ({
  //     root: {
  //         display: "flex",
  //         zIndex: 999,
  //         // width: "140px",
  //     },
  //     paper: {
  //         marginRight: theme.spacing(2),
  //     },
  // }));

  /******* Calling Dynamic URL ********/

  useEffect(
    // "This function will fetch the data of user profile when user first visits on page"
    () => {
      let response = userData;
      if (response && response.data.profile.profile_status === true) {
        setUserProfile(true);
        setUserDetails(response.data);
        if (response.data.user.has_membership === true) {
          setUserMembership(response.data.user.package.name);
        } else {
          setUserMembership("free");
        }
      }
      if (headerCallback) {
        headerCallback(response.data);
      }
    },
    // eslint-disable-next-line
    []
  );

  // const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    //  "handle using for open the togglwe"
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    // "Ths function is works when user clicks away from popup "
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleChange(data) {
    // "This function is using for close the popup on onClick  of X icon"
    setSellerQuestion(data);
    setRefferalListing(data);
    setSellerListing(data);
    setBuyerQuestion(data);
    setBuyerRequest(data);
    setRefferalRequest(data);
  }

  function setListingOption(option) {
    // "This function we are using for open popup on referral side on click of seller button when buyer is false"
    setSellerQuestion(false);
    if (option === true) {
      setRefferalListing(true);
    } else {
      setSellerListing(true);
    }
  }

  function setReferralOption(option) {
    // "This function we are using for open popup on referral side on click of seller button when buyer is false"
    setBuyerQuestion(false);
    if (option === true) {
      setRefferalRequest(true);
    } else {
      setBuyerRequest(true);
    }
  }

  const logoutUser = () => {
    // "Function for logout the user it will triggered when user clicks on logout button"
    var chat_widget = document.getElementById("cometchat__widget");
    if (chat_widget) {
      window.CometChatWidget.logout().then((response) => {
        // User successfully logged out.
        // Perform any clean up if required.
        document.getElementById("cometchat__widget").remove();
        dispatch(logout());
      });
    } else {
      dispatch(logout());
    }
    setOpen(false);
  };

  const updateProfile = () => {
    // "Handle for redirect user to update user profile page will triggered when user clicks on profile button"
    if (userProfile) {
      history.push("/profile");
    } else {
      history.push("/userDetails");
    }
  };

  function handleListKeyDown(event) {
    // "Function for down the focus on click of tab button from keyboard"
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  function setActiveLink(event) {
    // "Using this function to redirect user to items for sale page it will triggered on on Click of "Items for sale button""
    console.log(event);
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    // " this function is using for focus on previous open"
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  function resetDefaultFilter() {
    // "Function for reset All the filters data this function will trigered when user clicks on website main logo"
    localStorage.removeItem("searchfilter");
    localStorage.removeItem("sessionTimes");
    localStorage.removeItem("Requestsearchfilter");
    localStorage.removeItem("RequestsessionTimes");
    localStorage.removeItem("Request_searchfilter");
    localStorage.removeItem("Request_sessionTimes");

    history.push("/");
    window.location.reload(true);
  }

  /******* Launch Chat Bot ********/

  const launchChat = (e) => {
    // Handle for launch  chat on click of "sell Button"
    // dashboard_chat
    if (e.target.id === "Buy") setBuyerQuestion(true);
    // triggers click event on chat
  };

  const buyLaunchChat = (e) => {
    // Handle for launch  chat on click of "Buy Button"
    // dashboard_chat
    if (e.target.id === "Sell") {
      setSellerQuestion(true);
      /*//const chatNode = document.getElementsByClassName('buy-chat')[0].getElementsByClassName('rcw-launcher')[0];
            //if (chatNode ==== 'undefined') return;
            //chatNode.click(); // triggers click event on chat*/
    }
  };

  const RefferalConfirm = (props) => {
    return (
      <>
        <div className="col-sm-5 posRel">
          <div className="stepFormContainer">
            {
              <button
                type="button"
                className="closeBtn"
                onClick={() => handleChange(false)}
              >
                <span aria-hidden="true">x</span>
              </button>
            }
            <div className="stepFormMidSec">
              <div className="stepTopSection">
                {/* <img className='logoImg' src={LOGO} /> */}
                {/* <img className='logoImg' src={LOGO} /> */}
                <LazyLoadImage
                  src={LOGO}
                  alt="Logo"
                  effect="blur"
                  className="logoImg"
                />
              </div>
              <div className="step well ">
                <div className="midBoxBut">
                  <h5>Please confirm which action you want to perform ?</h5>
                  <br />
                  <button
                    className="customBluebut btn btn-primary"
                    onClick={() => {
                      props.user_type === "seller"
                        ? setListingOption(false)
                        : setReferralOption(false);
                      dispatch(listingActions.reset());
                    }}
                  >
                    {props.user_type === "seller"
                      ? "Upload Own Listing"
                      : "Upload Own Request"}
                  </button>

                  <button
                    className="customBluebut btn btn-primary"
                    onClick={() => {
                      props.user_type === "seller"
                        ? setListingOption(true)
                        : setReferralOption(true);
                      dispatch(referralListingActions.reset());
                    }}
                  >
                    {props.user_type === "seller"
                      ? "Upload Refferal Listing"
                      : "Upload Refferal Request"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="stepFormBgOverlay"></div>
      </>
    );
  };

  const [packageStatus, setPackageStatus] = useState();
  const [nextBillingDate, setBillingDate] = useState();

  const calculateTimeLeft = () => {
    let subscriptionEndTime = nextBillingDate;
    const difference = +new Date(subscriptionEndTime) - +new Date();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  // useEffect(() => {
  //     setTimeout(() => {
  //         setTimeLeft(calculateTimeLeft());
  //     }, 1000);
  // });

  const timerComponents = [];
  // console.log('timerComponents===>', timerComponents)

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
    // console.log('timerComponents===>', timerComponents)
  });

  useEffect(() => {
    // "Function for get user  and get buyer package it will triggered ehren usercomes first time on page or refresh the page"
    if (userData != undefined) {
      setUserDropdown(true)
      getUserPackage() // Api function for get user package via api services
        .then((response) => {
          // console.log('response name===>', response)
          if (response.data.status === true) {
            // let x = new Date(response.data.data.subscription_end);
            // let billingDay = `${x.toLocaleString('default', { month: 'short' })} ${x.getUTCMonth()}, ${x.getFullYear()}`;
            // console.log('billingDay===', billingDay)
            setBillingDate(response.data.data.subscription_end);
            setPackageStatus(response.data.data.free_package);
          }
        });
    }

  }, [userData]);

  console.log('userData', userData)
  return (
    <>
      <header className="page-header" id="page-header">
        <div className="container-fluid">
          <nav>
            <div className="nav-left">
              <a>
                <img src={LOGO2} alt="forecastr logo" className="signup_logo" onClick={resetDefaultFilter}
                />
              </a>
            </div>
            <div className="d-flex">
              {/* <button type="button" className="help_btn">
                <img src={HELP} className="help_icon" alt="help logo" />
                Help
              </button> */}
              {
                userDropdown ?
                  <div className="user-menu-wrap">
                    <div
                      className="account-icon"
                      ref={anchorRef}
                      aria-controls={open ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      onClick={handleToggle}
                    >
                      <a className="mini-photo-wrapper">
                        <img className="mini-photo" src={AVATAR} alt="mini logo" />
                        <div>
                          <img
                            src={DROPDOWN}
                            className="header-dropdown"
                            alt="dropdown"
                          />
                        </div>
                      </a>

                      {/* <Avatar alt="Remy Sharp" src={AVATAR} />
                                    <LazyLoadImage src={AVATARDROP} alt='AVATARDROP' effect="blur" className="ml-2 img-fluid" style={{ cursor: "pointer" }} /> */}
                    </div>
                    <Popper
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom"
                                ? "center top"
                                : "center bottom",
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList
                                autoFocusItem={open}
                                id="menu-list-grow"
                                onKeyDown={handleListKeyDown}
                              >
                                <ul className="user-menu">
                                  <li className="user-menu__item">
                                    <a
                                      className="user-menu-link"
                                      href="javascript:void(0)"
                                    >
                                      <div
                                        onClick={logoutUser}
                                        style={{ color: "#F44336" }}
                                      >
                                        Logout
                                      </div>
                                    </a>
                                  </li>
                                </ul>

                                {/* <MenuItem onClick={updateProfile}>Profile  <br />{userProfile ? <>(Membership: {userMembership}) </> : ''}</MenuItem> */}
                                {/* <MenuItem onClick={logoutUser}>Logout</MenuItem> */}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                  : ''}
            </div>
          </nav>
          <div className="d-flex">
            {/* <div className="search_field_header d-lg-none d-md-none">
              <span className="material-icons">
                <img
                  src="assets/img/dashboard/Icon feather-search.png"
                  alt="forecastr logo"
                />
              </span>
              <input
                type="text"
                className="search"
                placeholder="Please Type Here"
              />
            </div>
            <div>
              <img
                src="assets/img/dashboard/Icon ionic-ios-list_white.png"
                alt="forecastr logo"
                className="icon_map_header d-lg-none d-md-none"
              />
            </div> */}
          </div>
        </div>
      </header>
    </>
  );
};

export default AddUserDetails;
