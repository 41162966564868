import React, { Component } from "react";

import GoogleMapReact from "google-map-react";

import styled from "styled-components";

import Autocomplete from "react-google-autocomplete";

// const AutoComplete = lazy(() => import("./Autocomplete"));
import Marker from "./Marker";
// import Marker from './Marker';
const Wrapper = styled.main`
  width: 100%;
  height: 100%;
`;

class VesselMyGoogleMap extends Component {
  constructor(props) {
    super(props);
    this.handleAddressUpdate = this.handleAddressUpdate.bind(this);
  }

  handleAddressUpdate(newAddr) {
    // handle for update the address
    this.props.parentCallback(newAddr);
  }

  state = {
    // state for manage map data
    mapApiLoaded: false,
    mapInstance: null,
    mapApi: null,
    geoCoder: null,
    places: [],
    center: {
      lat: this.props.userData
        ? this.props.userData.latitude
        : this.props.vesselLocation && this.props.vesselLocation.lat != null
        ? parseFloat(this.props.vesselLocation.lat)
        : 45.1314415,
      lng: this.props.userData
        ? this.props.userData.longitude
        : this.props.vesselLocation && this.props.vesselLocation.lng != null
        ? parseFloat(this.props.vesselLocation.lng)
        : -66.353824,
    },
    zoom: 7,
    address:
      this.props.vesselLocation != null
        ? this.props.vesselLocation.address
        : "",
    draggable: true,
    lat: this.props.userData
      ? this.props.userData.latitude
      : this.props.vesselLocation && this.props.vesselLocation.lat != null
      ? this.props.vesselLocation.lat
      : 45.1314415,
    lng: this.props.userData
      ? this.props.userData.longitude
      : this.props.vesselLocation && this.props.vesselLocation.lng != null
      ? this.props.vesselLocation.lng
      : -66.353824,
  };

  onMarkerInteraction = (childKey, childProps, mouse) => {
    // this function is used for set the value of draggable and lat lng
    this.setState({
      draggable: false,
      lat: mouse.lat,
      lng: mouse.lng,
    });
  };
  onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
    this.setState({ draggable: true });
    this._generateAddress();
  };
  _onChange = ({ center, zoom }) => {
    // this function is using for change the location
    this.setState({
      center: center,
      zoom: zoom,
    });
  };

  _onClick = (value) => {
    // this function is using for change the value
    this.setState({
      lat: value.lat,
      lng: value.lng,
    });
  };

  apiHasLoaded = (map, maps) => {
    this.setState({
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
    });

    this._generateAddress();
  };

  onPlaceSelected = (place) => {
    if (place.geometry !== undefined) {
      const latValue = place.geometry.location.lat(),
        lngValue = place.geometry.location.lng();
      this.setState({
        places: [place],
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        center: [latValue, lngValue],
        zoom: 17,
      });
      this._generateAddress();
    }
  };

  addPlace = (place) => {
    this.setState({
      places: [place],
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });

    this._generateAddress();
  };

  _generateAddress() {
    const { mapApi } = this.state;

    const geocoder = new mapApi.Geocoder();

    geocoder.geocode(
      { location: { lat: this.state.lat, lng: this.state.lng } },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 12;
            this.setState({ address: results[0].formatted_address });
            var address = results[0].address_components;
            var zipcode,
              state_id,
              country_id = "";
            for (var i = 0; i < address.length; i++) {
              if (address[i].types.includes("postal_code")) {
                zipcode = address[i].short_name;
              }
              if (address[i].types.includes("administrative_area_level_1")) {
                state_id = address[i].short_name;
              }
              if (address[i].types.includes("country")) {
                country_id = address[i].short_name;
              }
            }
            this.handleAddressUpdate({
              address: results[0].formatted_address,
              postal_code: zipcode,
              state_id: state_id,
              country_id: country_id,
              lat_lng: this.state.lat + "_" + this.state.lng,
            });
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }

  componentDidUpdate = async (prevProps) => {
    if (
      this.props.userData &&
      this.props.userData.latitude !== prevProps.userData.latitude
    ) {
      this.setState({
        center: [
          parseFloat(this.props.userData.latitude),
          parseFloat(this.props.userData.longitude),
        ],
        lat: parseFloat(this.props.userData.latitude),
        lng: parseFloat(this.props.userData.longitude),
      });
    } else if (
      this.props.vesselLocation &&
      this.props.vesselLocation.lat !== prevProps.vesselLocation.lat
    ) {
      this.setState({
        center: [
          parseFloat(this.props.vesselLocation.lat),
          parseFloat(this.props.vesselLocation.lng),
        ],
        lat: parseFloat(this.props.vesselLocation.lat),
        lng: parseFloat(this.props.vesselLocation.lng),
      });
      const { mapApi } = this.state;

      const geocoder = new mapApi.Geocoder();

      geocoder.geocode(
        {
          location: {
            lat: parseFloat(this.props.vesselLocation.lat),
            lng: parseFloat(this.props.vesselLocation.lng),
          },
        },
        (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              this.zoom = 12;
              this.setState({ address: results[0].formatted_address });
              var address = results[0].address_components;
              var zipcode,
                state_id,
                country_id = "";
              for (var i = 0; i < address.length; i++) {
                if (address[i].types.includes("postal_code")) {
                  zipcode = address[i].short_name;
                }
                if (address[i].types.includes("administrative_area_level_1")) {
                  state_id = address[i].short_name;
                }
                if (address[i].types.includes("country")) {
                  country_id = address[i].short_name;
                }
              }
              this.handleAddressUpdate({
                address: results[0].formatted_address,
                postal_code: zipcode,
                state_id: state_id,
                country_id: country_id,
                lat_lng: this.state.lat + "_" + this.state.lng,
              });
            } else {
              window.alert("No results found");
            }
          } else {
            window.alert("Geocoder failed due to: " + status);
          }
        }
      );
    }
  };

  render() {
    // const { mapApiLoaded } = this.state;

    return (
      <Wrapper>
        <div>
          {/*<AutoComplete map={mapInstance} mapApi={mapApi} addplace={this.addPlace} /> */}
          <Autocomplete
            style={{ width: "90%" }}
            onPlaceSelected={this.onPlaceSelected}
            options={{
              types: [
                "street_address",
                "route",
                "locality",
                "political",
                "postal_code",
              ],
            }}
          />
        </div>

        <GoogleMapReact
          style={{ padding: "10em", position: "relative" }}
          center={this.state.center}
          zoom={this.state.zoom}
          defaultCenter={{ lat: 45.1314415, lng: -66.353824 }}
          draggable={this.state.draggable}
          onChange={this._onChange}
          onChildMouseDown={this.onMarkerInteraction}
          onChildMouseUp={this.onMarkerInteractionMouseUp}
          onChildMouseMove={this.onMarkerInteraction}
          // onChildClick={() => console.log('child click')}
          onClick={this._onClick}
          bootstrapURLKeys={{
            key: "AIzaSyA45ljVVp5CdLnhEpgc2BxXUpMyKe3w47Q",
            libraries: ["places", "geometry"],
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
        >
          <Marker
            text={this.state.address}
            lat={this.state.lat}
            lng={this.state.lng}
            // center={{lat: 45.1314415, lng: -66.353824}}
            // lat={45.1314415}
            // lng={-66.353824}
            icon={{
              url: "http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|808080",
            }}
          />
        </GoogleMapReact>

        <div className="info-wrapper">
          {/* <div className="map-details">Latitude: <span>{this.state.lat}</span>, Longitude: <span>{this.state.lng}</span></div>
                    <div className="map-details">Zoom: <span>{this.state.zoom}</span></div> */}
          <div className="map-details">
            <b>Address:</b> <span>{this.state.address}</span>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default VesselMyGoogleMap;
