import React from "react";
import { ICONFEATHER, ICONSEARCH, MOBILEMENU } from "../../../constants/imagepath";
import AutoSearchSuggestions from "../../AutoSearchSuggestions";
import axios from "axios";
import { useState, useEffect, lazy } from "react";
import { Link } from "react-router-dom";

export default function SearchFilterMobile({ dashboardFunc,openInfoModal }) {
  const [searchType, setSearchType] = useState([]);
  const [searchId, setSearchId] = useState([]);
  const [searchData, setSearchData] = useState(null);
  const [suggestionClicked, setsuggestionClicked] = useState(false);
console.log('dashboardFunc', dashboardFunc)
  let cancelToken;
  const parentToChild = (indexss) => {
    dashboardFunc(indexss);
  };

  /******* Calling Dynamic URL ********/
  const apiUrl = process.env.REACT_APP_API_KEY;

  function handleChange(data) {
    // "Handle change for set values and send them to  AutoSearchSuggestions component
    setSearchType(data.searchType);
    setSearchId(data.searchId);
    setsuggestionClicked(data.clicked);
    document.getElementById("search_input").value = data.search_query;
    setSearchData(null);
  }

  const changeInputText = async (event) => {
    // "Function for post autocomplete-search?q= api data  "
    var searchText = event.target.value;
    var searchLength = searchText.length;

    if (searchLength > 1) {
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }

      //Save the cancel token for the current request
      cancelToken = axios.CancelToken.source();
      var config = {
        method: "get",
        url: apiUrl + "autocomplete-search?q=" + searchText,
        cancelToken: cancelToken.token,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      try {
        await axios(config)
          .then(function (response) {
            var responseData = response.data;
            if (response.data.status) {
              setSearchData(responseData);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      setSearchData(null);
    }
  };

  return (
    <>
      <div className="">
      <button type="button"  className=" ct_map_icon_12   d-flex  d-lg-none d-md-none" style={{ whiteSpace: "nowrap", height: "43px", width: "100%"}}       onClick={openInfoModal}
      >
      Information Research
        </button>
        <Link className="user-menu-link"  to={"/job"}>
                <button type="button" className="help_btn ct_mobile_help_btn  ct_map_icon_12 w-auto d-block d-md-none me-2" style={{ whiteSpace: "nowrap" }} >
                 Jobs
                </button>
                </Link>
        <div className="search_field_header w-100 d-lg-none d-md-none px-0">
          <span className="material-icons" style={{ left: "15px" }}><img src={ICONFEATHER} /></span>
          <input
            type="text"
            className="search mb-0 ml-0"
            placeholder="Please Type Here"
            onChange={changeInputText}
            name="search-input"
            id="search_input"
          />
          {searchData != null ? (
            <AutoSearchSuggestions
              searchData={searchData}
              onChange={handleChange}
              parentToChild={parentToChild}
            />
          ) : (
            <></>
          )}
        </div>
        {/* <div>
          <img src={MOBILEMENU} className="icon_map_header d-lg-none d-md-none" />
        </div> */}
      </div>
    </>
  );
}
