import axios from "axios";
import { useState, useEffect } from "react";
import VesselMyGoogleMap from "../../GoogleMap/vesselMyGoogleMap";
// import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  updateVesselLocation,
  listingActions,
  listingSelector,
} from "../../../store/listingSlice";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import SearchSelect from "../../selectSearch/SearchSelect";
import { getSubSpeciesById } from "../../../api_services/api_calls/listingObjectApi";

const Step4 = ({
  handleFormData,
  handleChangeNormal,
  handleFormDataOther,
  values,
  clearErrors,
  selectValues,
  register,
  error,
  handleAddress,
  getValues,
  setValue,
  control,
}) => {
  const dispatch = useDispatch();

  /******* Calling Dynamic URL ********/
  // const apiUrl = process.env.REACT_APP_API_KEY;
  const [filteredLicenseArea, setFilteredLicenseArea] = useState([]); // state for store the data of filtered licenses area

  const [subSpecies, setSubSpecies] = useState([]); // state for store the data of subspecies of license
  const { location } = useSelector(listingSelector);

  const changeAddress = (childData) => {
    if (handleAddress !== undefined) {
      handleAddress({
        address: childData.address,
        state_id: childData.state_id,
        country_id: childData.country_id,
        postal_code: childData.postal_code,
        lat_lng: childData.lat_lng,
      });
      dispatch(listingActions.setSelectedLocation(childData));
      dispatch(listingActions.setSelectedLocation(childData));
    }
  };

  useEffect(() => {
    changeSpecies(getValues('LicenseSpecies'))
  },
    // eslint-disable-next-line
    [getValues('LicenseSubSpecies')]
  );

  //handle for set the species of license area and subspecies fields on onChange of input values
  const changeSpecies = (e) => {
    let val = e;
    setFilteredLicenseArea(
      Array.from(
        selectValues.brokerArea.filter(function (x) {
          return x.generalspecies2[0]?.general_species_id === e;
        })
      )
    );
    getSubSpecies(val);
  };



  const getSubSpecies = (id) => {
    // var config = {
    //   method: "get",
    //   url: apiUrl + "get-sub-species/" + id,
    //   headers: {
    //     Authorization: "Bearer " + localStorage.getItem("token"),
    //   },
    // };

    getSubSpeciesById(id)
      // axios(config)
      .then(function (response) {
        setSubSpecies(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getLocationByVessel = (selectedOption) => {
    let id = selectedOption;
    dispatch(
      updateVesselLocation({
        berth_id: id,
        token: localStorage.getItem("token"),
      })
    ).then(() => console.log("success"));
  };

  const items = [];
  useEffect(
    () => {
      selectValues.berths.map(
        (berth, index) => items.push({ value: berth.id, label: berth.name })
        // setBerths(items)
      );
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      {values.ItemType === "Boat" || values.PackageItemType === "Boat" ? (
        <div>
          <p className="good_day_para">
            Where Is The Vessel Present Berthed At Or Stored?
          </p>
          <p className="example_text">Example: Yarmouth Bar, West Head Warf</p>
          <div className="row">
            <div className="col-md-12">
              <span className="popup_input_label">Ship Registration Area</span>
            </div>
            <div className="col-md-12">
              <Controller
                name="VesselLocation"
                control={control}
                defaultValue=""
                rules={{ required: "This field is required" }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <SearchSelect
                    onChange={(selectedOption) => {
                      setValue("VesselLocation", selectedOption?.value);
                      getLocationByVessel(selectedOption?.value);
                      handleFormData({
                        target: {
                          value: selectedOption?.value,
                          name: "VesselLocation",
                        },
                      });
                      clearErrors("VesselLocation");
                    }}
                    defaultValue={
                      selectValues.berths.find(
                        (e) => e.id === getValues("VesselLocation")
                      )
                        ? {
                          value: selectValues.berths.find(
                            (e) => e.id === getValues("VesselLocation")
                          ).id,
                          label: selectValues.berths.find(
                            (e) => e.id === getValues("VesselLocation")
                          )?.name,
                        }
                        : ""
                    }
                    options={selectValues.berths.map((berth, index) => {
                      return { value: berth.id, label: berth.name };
                    })}
                  />
                )}
              />
              {error.VesselLocation && (
                <span className="err-msg">{error.VesselLocation.message}</span>
              )}
            </div>
            <div className="col-md-12">
              <span className="popup_input_label">Ship Registration Area</span>
              <VesselMyGoogleMap
                parentCallback={changeAddress}
                vesselLocation={location}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <span className="popup_input_label">
              General Species<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="LicenseSpecies"
              control={control}
              // defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelect
                  onChange={(selectedOption) => {
                    if (selectedOption.value !== getValues('LicenseSpecies')) {
                      setValue("LicenseSubSpecies", '')
                    }
                    setValue("LicenseSpecies", selectedOption?.value);
                    changeSpecies(selectedOption.value);
                    handleFormDataOther({
                      target: {
                        value: selectedOption?.value,
                        name: "LicenseSpecies",
                      },
                    });
                    clearErrors("LicenseSpecies");
                  }}
                  defaultValue={
                    selectValues.generalSpecies.find(
                      (e) => e.id === getValues("LicenseSpecies")
                    )
                      ? {
                        value: selectValues.generalSpecies.find(
                          (e) => e.id === getValues("LicenseSpecies")
                        ).id,
                        label: selectValues.generalSpecies.find(
                          (e) => e.id === getValues("LicenseSpecies")
                        )?.name,
                      }
                      : ""
                  }
                  options={selectValues.generalSpecies?.map((berth, index) => {
                    return { value: berth.id, label: berth.name };
                  })}
                />
              )}
            />
            {error.LicenseSpecies && (
              <span className="err-msg">{error.LicenseSpecies.message}</span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Sub Species<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="LicenseSubSpecies"
              control={control}
              // defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelect
                  onChange={(selectedOption) => {
                    setValue("LicenseSubSpecies", selectedOption?.value);
                    handleFormDataOther({
                      target: {
                        value: selectedOption?.value,
                        name: "LicenseSubSpecies",
                      },
                    });
                    clearErrors("LicenseSubSpecies");
                  }}
                  defaultValue={
                    subSpecies.find((e) => e.id === getValues("LicenseSubSpecies"))
                      ? {
                        value: subSpecies.find((e) => e.id === getValues("LicenseSubSpecies"))?.id,
                        label: subSpecies.find((e) => e.id === getValues("LicenseSubSpecies"))?.name,
                      }
                      : ""
                  }
                  options={subSpecies?.map((species, index) => {
                    return { value: species.id, label: species.name };
                  })}
                />
              )}
            />
            {error.LicenseSubSpecies && (
              <span className="err-msg">{error.LicenseSubSpecies.message}</span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Areas<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="LicenseArea"
              control={control}
              // defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelect
                  onChange={(selectedOption) => {
                    setValue("LicenseArea", selectedOption?.value);
                    handleFormDataOther({
                      target: {
                        value: selectedOption?.value,
                        name: "LicenseArea",
                      },
                    });
                    clearErrors("LicenseArea");
                  }}
                  defaultValue={
                    filteredLicenseArea.find(
                      (e) => e.id === getValues("LicenseArea")
                    )
                      ? {
                        value: filteredLicenseArea.find(
                          (e) => e.id === getValues("LicenseArea")
                        ).id,
                        label: filteredLicenseArea.find(
                          (e) => e.id === getValues("LicenseArea")
                        )?.name,
                      }
                      : ""
                  }
                  options={filteredLicenseArea?.map((species, index) => {
                    return { value: species.id, label: species.name };
                  })}
                />
              )}
            />

            {error.LicenseArea && (
              <span className="err-msg">{error.LicenseArea.message}</span>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Step4;
