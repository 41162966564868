import { React, useEffect, useState, lazy } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchUserBytoken, userSelector } from "../store/userSlice";
import { Spinner } from 'reactstrap';
import SiteOverview from '../screens/SiteOverview/SiteOverview';
// import axios from "axios";

const AddUserDetails = lazy(() => import('../screens/AddUserDetails/AddUserDetails'));

const PrivateRoute = ({ component: Component, ...rest }) => {
  // const apiUrl = process.env.REACT_APP_API_KEY;
  const { loggedInStatus, profileStatus, privateLoadingStatus } = useSelector(userSelector);
  const dispatch = useDispatch();


  const [changeDone, setChangeDone] = useState(false); // "State for manage change status"
  const location = useLocation();
  const currentLocation = location.pathname;
  useEffect(() => { // useEffect for dispatch fetchUserBytoken and change done will be true
    dispatch(fetchUserBytoken({ token: localStorage.getItem('token') })).then(() =>
      setChangeDone(true)
    );
    // eslint-disable-next-line
  }, []);
 
  return (

    <Route {...rest} render={props => (
      <div>
        {
          (() => {
            if (privateLoadingStatus === "loaded" && changeDone) {
              if (loggedInStatus === true) {
                if (profileStatus === true) {
                  return (
                    <Component {...props} />
                  )
                } else {
                  return (
                    <>
                      <AddUserDetails redirectFrom={'private'} />

                    </>)
                }
              } else {
                return (location.pathname.includes('/upload-listing-images') ?
                  <Redirect to={{
                    pathname: '/',
                    state: { from: currentLocation }
                  }} /> : <Redirect to="/" />
                )
              }
            } else if (privateLoadingStatus === "loading") {
              return <Spinner />
            } else if (privateLoadingStatus === "error") {
              return (location.pathname.includes('/upload-listing-images') ?
                <Redirect to={{
                  pathname: '/',
                  state: { from: currentLocation }
                }} /> : <Redirect to="/" />
              )
            }
          })()
        }
      </div>
    )} />
  );
};

export default PrivateRoute;